.DropzoneContainer .DropzoneBody {
  text-align: center;
  padding: 35px;
  border-style: none;
  border-width: 0px;
  border-color: #fafafc;
  background-color: #f6f6fa;
  margin-bottom: 20px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%230A0A0A45' stroke-width='1' stroke-dasharray='7' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");
}
.ErrorAttach {
  border: 1px solid rgb(196, 49, 75);
}
.templateAttachmentClass .ui-attachment,
.attachmentClass .ui-attachment {
  max-width: 100%;
}
.UploadButton {
  font-size: 30px;
}
.Browse {
  color: rgb(40, 96, 201);
  font-weight: 600;
  cursor: pointer;
}
.BrowseDisabled {
  color: grey;
  font-weight: 600;
}
.templateAttachmentClass .ui-attachment {
  background-color: #f6f6fa;
}
.templateAttachmentClass .ui-attachment:hover {
  background-color: #f6f6fa;
}

.drpzone_error .DropzoneBody {
  border: 0.03vh solid rgb(196, 49, 75);
}
.ui-attachment__body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
