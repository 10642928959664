.loadMoreBtn {
    padding: 8px;
    color: #fff;
    background-color: #000;
    border-radius: 4px;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 60px;
    font-size: 11px;
    cursor: pointer;
  }
  