.contactUsOption {
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 5px 0;
}

.menuIcon {
    font-size: 2rem;
    width: 1.5rem;
    position: relative;
    cursor: pointer;
}

.contactus_icon {
    width: 18px;
    position: relative;
    cursor: pointer;
    opacity: 0.85;
}