.textareaContainer {
  /* display: flex;
  flex-direction: column; */
  background-color: rgb(243 242 241);
}

.custom-editor {
  padding: 10px;
  max-width: 100%;
}
.textareaContainer a {
  cursor: pointer;
}
.linkprev-controller:focus {
  border-bottom: inherit !important;
}

.linkprev-controller a {
  cursor: pointer;
}
.linkprev-controller {
  overscroll-behavior: contain;
}

.editor-inner {
  max-height: inherit !important;
}
/*stop zooming on focus */
@media only screen and (min-width: 320px) and (max-width: 500px) {
  .textareaContainer {
    font-size: 16px;
  }
  .linkprev-controller {
    font-size: 16px !important;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch
  }
}
