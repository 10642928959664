.register-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  position: absolute;
}

.register-form {
  width: 480px;
  margin: 40px auto 20px auto;
  border: 1px solid #ddd;
  padding: 1.5rem 4rem 1.5rem 4rem;
  background: #ffffff;
}

.form-header-register {
  text-align: left;
  margin-bottom: 1.2rem;
}
.form-header-image-register {
  margin-bottom: 1rem;
}

.form-header-heading-register {
  font-size: 25px;
  font-weight: 700;
  display: flex;
}
.form-group-label-register {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 1.2rem;
}
.form-group-label-signin {
  font-size: 16px;
  font-weight: 600;
  padding-top: 1.2rem;
}
.country-dropdown > div > div > button > span {
  font-weight: 600;
  color: #747480;
}
.form-group input,
.form-group select,
.cursor-class {
  cursor: pointer !important;
}

.p-text-already-registered {
  font-size: 16px;
  font-weight: 600;
  color: rgb(196, 49, 75);
}
