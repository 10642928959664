.navBarTabs {
  width: 100%;
  position: sticky;
  display: flex;
  background: white;
  align-items: center;
  top: 0;
  height: 50px;
  border-bottom: 0.5px solid #74748055;
  z-index: 9;
  display: flex;
  align-items: center;
  padding: 0 14px 0 18px;
  justify-content: space-between;
}

.navBarTabs__right-section {
  display: flex;
  gap: 50px;
}

.navBarTabs__right-section__logo {
  display: flex;
  align-items: center;
  gap: "5px";
}

.navBarTabs__right-section__logo__image {
  height: 37px !important;
  border-radius: 5px;
}

.navBarTabs__right-section__logo__heading {
  font-size: 18px;
  font-weight: 700;
  margin-left: 10px;
}

.navBarTabs__right-section__tabs {
  display: flex;
  gap: 15px;
  align-items: center;
}

.navBarTabs__left-section__profile-image {
  height: 37px;
  border-radius: 40px;
  cursor: pointer;
}

.navBarTabs__left-section__logout-image {
  mix-blend-mode: multiply;
  filter: contrast(1.1);
  height: 30px !important;
}

.logoutMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.navBarTabs__left-section .ui-menu__item {
  padding-bottom: 0px;
  padding-top: 4px;
}

@media only screen and (max-width: 470px) {
  .navBarTabs__right-section {
    gap: 0px;
  }
  .navBarTabs__right-section__tabs {
    gap: 0px;
  }
  .navBarTabs__right-section__logo__heading {
    font-size: 17px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
     overflow: hidden;
  }
  .navBarTabs {
    padding: 0 5px 0 10px;
  }
}
