.comment-two-line-text {
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.showLessClass {
  height: auto;
}
.additionalClass {
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  word-break: break-word;
  -webkit-line-clamp: 2;
}

.showMoreClass {
  word-break: break-word;
  display: block;
  -webkit-line-clamp: none;
  overflow: visible;
  -webkit-box-orient: unset;
}

.showMoreLessText {
  font-size: 12px;
  color: #5b5fc7;
  cursor: pointer;
}

.comment-divider {
  margin: 3px;
}

/* This is very specific to the iphones*/
@media only screen and (min-width: 320px) and (max-width: 428px) {
  .comment-two-line-text {
    height: 3.5em;
  }
  .showLessClass {
    height: 3em;
  }
  .showMoreClass {
    height: max-content;
  }
}
