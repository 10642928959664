.startShare{
    display: flex;
    justify-content: center;
    padding: 3em;
}

.actionBox{
    text-align: center;
    width: 7em;
}

.IconCircleContainer{
    border-radius: 50%;
    width: max-content;
    margin: auto;
    padding: 1rem;
    background-color: rgba(128, 128, 128, 0.297);
    margin-bottom: 5px;
    cursor: pointer;
}

.modalActionIcon{
    font-size: 1.8em;
}

.actionBox span{
    font-size: small;
    margin-top: 1.5em;
}

.disabled{
    opacity: 0.45;
    background-color: white !important;
}

.disabled .IconCircleContainer{
    cursor: default;
}

@media only screen and (min-width: 320px) and (max-width: 780px){

    .startShare{
        gap: 1rem;
        justify-content: center;
    }
}