.justifyContent {
  text-align: justify;
  margin-left: 0px !important;
}

.privacyPopupStyle .ui-dialog__content {
  margin-bottom: 0px;
}

.privacyPopupStyle .ui-dialog__footer {
  padding: 0;
}

@media screen and (min-width: 800px) {
  .externalCompanyPopup {
    min-width: 70%;
  }
}

.hideButton {
  display: none;
}
