.commentContainer {
  width: 100%;
}

.comment {
  display: flex;
  gap: 15px;
  padding: 10px;
  /* border-radius: 4px; */
  position: relative;
}

.comment:hover {
  background: #f4f4f4;
}

.comment__mycomment::after {
  content: "";
  border-left: 3.5px solid #5b5fc7;
  left: 0;
  position: absolute;
  height: 100%;
  top: 0;
}

.comment-author__avatar {
}

.comment-author__avatar_img {
  border-radius: 50%;
}

.comment-body {
}

.comment-author__details {
  display: flex;
  gap: 15px;
  align-items: end;
}

.comment-author__name {
  font-size: 13px;
  font-weight: bold;
}

.comment-datetime {
  font-size: 12px;
}

.comment-body__text {
  /* height: max-content; */
  margin-top: 5px;
  font-size: 14px;
}

.text-component {
  position: relative;
  line-height: 1.5em;
  height: 3em;
  /* Adjust the height as per your requirement */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* Show only two lines of text */
}

.text-component.expanded {
  -webkit-line-clamp: unset;
  /* Remove the line clamp to show all text */
  height: auto;
  /* Remove the height restriction */
}

.fade-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2em;
  background: linear-gradient(rgba(255, 255, 255, 0), white);
  /* Fade effect to overlay the truncated text */
}

.text-content {
  margin-bottom: 1em;
  /* Add some spacing between text and button */
}

.show-more-button {
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
}

.icon-BiDownArrow {
  font-size: 20px;
  color: #c5c5c5;
}

.accordion {
  background-color: #fbfbfb;
  border-top: 1px solid #ececec;
}

.accordion-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 6px;
  /* padding-bottom: 2px; */
}

.accordion-header-text {
  font-size: 12px;
  color: #5b5fc7;
}

.accordion-divider {
  margin: 2px 20px;
}

.accordion-header .p2 {
  padding-left: 15px;
  padding-bottom: 5px;
}

.comment-box {
  position: relative;
  margin-top: 1px;
}

.comment-action_btns {
  /* text-align: center;
  position: absolute;
  right: 10px;
 */
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 0;
  gap: 10px;
  padding-right: 10px;
  width: 30px;
  height: 30px;
}

/* .comment:hover .comment-action_btns {
  visibility: visible;
} */

.comment-deleted {
  display: flex;
  gap: 10px;
  padding: 10px 0px 10px 25px;
  align-items: center;
  font-size: 14px;
}

.comment-delete_icon {
}

.comment-delete_msg {
}

.comment-undo_delete {
  color: #5b5fc7;
  cursor: pointer;
}

.comments-loader {
  position: relative;
}

.comments-loader svg {
  height: 15px !important;
  margin-top: 10px;
}

.comment-loading svg {
  height: 20px !important;
}

.delete-reply-loader .ui-loader__indicator svg {
  width: 18px;
  height: 18px;
}

.undo-reply-loader .ui-loader__indicator svg {
  width: 14px;
  height: 14px;
  margin-top: 12px;
}

.emoji-ellipsis-div {
  font-size: "x-small";
  display: "flex";
  justify-content: "center";
  align-items: "center";
}

.emoji-ellipsis-img {
  margin: 0 0 4px 2px;
}
