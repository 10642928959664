/* .wlcm-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    gap: 32px;
    background-color: #f5f5f5 !important;
    height: calc(100vh - 40px);}


.wlcm-container .wlcm-img{
    

}
.wlcm-container .wlcm-img img{
    height: 230px;

}
.wlcm-txt{
    text-align: center;
   
}
.wlcm-container .wlcm-txt-1{
    font-weight: bold;
    font-size: 18px;

}
.wlcm-container .wlcm-txt-2{
   margin-top: 8px ;
   font-size: 15px;
}

.wlcm-container .teamsbtn button {
    width:max-content
} */


.wlcm-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    gap: 32px;
    background-color: #f5f5f5 !important;
    min-height: 680px;
    height: calc(100vh - 40px);
    /* overflow: hidden; */
}

.wlcm-container .wlcm-img img {
    height: 230px;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
}

.wlcm-txt {
    text-align: center;
}

.wlcm-container .wlcm-txt-1 {
    font-weight: bold;
    font-size: 18px;
}

.wlcm-container .wlcm-txt-2 {
    font-weight: 700;
}

.wlcm-container .teamsbtn button {
    width: max-content;
}

.BB-detail-div {
    margin-bottom: 12px;
}

.BB-detail-div:last-child {
    margin-bottom: 0px;
}

img.BB-detail-logo {
    border-radius: 50%;
    width: 45px;
    margin-right: 10px;
}

.BB-detail-div .wlcm-txt-2{
    text-align: center;
}

.BB-detail-text {
    font-size: 14px;
}


@media (max-width: 1920px) {
    .wlcm-container .wlcm-txt-1 {
        font-size: 18px;
    }

    .wlcm-container .wlcm-txt-2 {
        font-size: 14px;
    }

    .wlcm-container .wlcm-img img {
        height: 230px;
    }
}

@media (max-width: 1280px) {
    .wlcm-container {
        padding-top: 30px;
        
    }
    .wlcm-container .wlcm-txt-1 {
        font-size: 18px;
    }

    .wlcm-container .wlcm-txt-2 {
        font-size: 14px;
    }

    .wlcm-container .wlcm-img img {
        height: 180px;
    }
    .wlcm-container .teamsbtn button span{
        font-size: 14px;
        
    }
    .wlcm-container .teamsbtn button {
       padding: 4px 13px;
    }

}

@media (max-width: 768px) {
   
    .wlcm-container .wlcm-txt-1 {
        font-size: 20px;
    }

    .wlcm-container .wlcm-txt-2 {
        font-size: 16px;
    }

    .wlcm-container .wlcm-img img {
        height: 130px;
    }
}

@media (max-width: 480px) {
    .wlcm-container .wlcm-txt-1 {
        font-size: 20px;
    }

    .wlcm-container .wlcm-txt-2 {
        font-size: 16px;
    }

    .wlcm-container .wlcm-img img {
        height: 90px;
    }

    .BB-detail-text {
        font-size: 16px;
    }
}

@media (max-width: 420px) {
    .wlcm-container .wlcm-txt-1 {
        font-size: 16px;
    }

    .wlcm-container .wlcm-txt-2 {
        font-size: 14px;
    }

    .wlcm-container .wlcm-img img {
        height: 90px;
    }

    .BB-detail-text {
        font-size: 14px;
    }

    .wlcm-container .teamsbtn button span{
        font-size: 14px;
    }
}
