.startCopyLink {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  bottom: 35px;
  position: relative;
  height: 10rem; /*Closest height to prototype indication*/
  z-index: 2;
}

.actionBoxCopyLink {
  width: 100%;
  /* height: 167px; */
  font-size: 46px;
  text-align: center;
}

.IconCheckmarkContainer {
  /* position: fixed; */
  left: 940px;
  top: 410px;
}

.linkCreated {
  margin: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.secondText {
  margin-top: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.linkField {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 8px;
  width: 100%;
  height: 32px;
  border: 1px solid #8a8886;
  border-radius: 2px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  overflow:hidden;
  text-align: left !important;
  white-space: nowrap;
}

.copyButton {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 20px;
  width: 73px;
  height: 32px;
  left: calc(50% - 73px / 2 + 115px);
  top: 165px;
  background: #ffffff;
  border: 1px solid #8a8886;
  border-radius: 2px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}
.buttonContainer {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

div[disabled] {
  display: none;
}


@media only screen and (min-width: 320px) and (max-width: 580px){

  .actionBoxCopyLink {
    width: 100%;
  }
}
