.close-btn {
  background-color: #fff;
  border-bottom: 1px solid #d3d3d3;
  height: 45px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 40px 0px 10px;
  position: sticky;
  top: 40px;
  z-index: 7;
}
.close-btn-icon {
  /* float: right; */
  /* width: 16px !important; */
  /* padding-top: 10px; */
  cursor: pointer;
  font-size: 0.87em;
}
.err-close {
  background-color: #fff;
  border-bottom: 1px solid #d3d3d3;
  height: 45px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 40px;
  position: relative;
  top: 5px;
  z-index: 7;
}

@media (max-width: 600px) {
  .close-btn {
    padding: 0px 25px 0px 10px;
  }
}