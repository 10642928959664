.notification-settings-container {
  margin-bottom: 2rem;
  margin-left: 0.5rem;
  border: 1px solid lightgrey;
}

.notification-alert .ui-alert {
  padding-left: 0 !important;
}

.notification-alert .ui-alert__content {
  display: flex;
}

.notitication-alert-iconclass {
  padding-left: 0px;
  padding-right: 7px;
  padding-top: 3px;
}

.notification-user-toggleContainer,
.notification-company-toggleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.text-container {
  padding-right: 1rem;
  min-width: 200px;
}

@media (min-width: 1024px) {
  .full-screen-view .notification-user-toggleContainer,
  .full-screen-view .notification-company-toggleContainer {
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 32px;
  }
  .full-screen-view .text-container {
    white-space: nowrap;
  }
  .full-screen-view .checkbox-containers {
    margin-left: 16px;
  }
}

.sidebar-view .notification-user-toggleContainer,
.sidebar-view .notification-company-toggleContainer {
  justify-content: space-between;
}

.sidebar-view .text-container {
  white-space: normal; /* Allow wrapping if needed */
}

.sidebar-view .checkbox-containers {
  margin-left: auto; /* Push the toggle switches to the right */
}

.text-container {
  white-space: nowrap;
}

.checkbox-containers {
  margin-left: 16px;
}
.checkbox-containers .ui-checkbox {
  /* background-color: #fff !important;
  opacity: 1 !important; */
  width: auto !important;
}
