.formDatePickerStyles button:hover {
  color: black;
  background-color: #f6f6fa !important;
}

.formDatePickerStyles input {
  border-bottom: 1px solid #2e2e38;
  border-bottom-style: revert;
  border-radius: 0;
  border-color: none;
  box-shadow: none;
  background-color: #f6f6fa;
  height: 33px;
}

.formDatePickerStyles button {
  border: none;
  border-bottom: 1px solid #2e2e38;
  border-bottom-style: revert;
  border-radius: 0;
  border-color: none;
  box-shadow: none;
  background-color: #f6f6fa;
  height: 33px;
}

.formDatePickerStyles .ui-datepicker {
  display: flex;
  align-items: end;
}

.formDatePickerStyles .ui-datepicker .ui-input__input {
  min-height: 8vh;
  font-size: 14px;
  padding-top: 1rem;
}

.formDatePickerStyles .ui-datepicker .ui-button {
  min-height: 8vh;
}

.formDatePickerStyles .ui-input__icon {
  cursor: pointer;
}

.date_error .ui-box {
  border-bottom-color: rgb(196, 49, 75) !important;
}

.date_error .ui-button {
  border-bottom-color: rgb(196, 49, 75) !important;
}

.datePlaceHolderClass {
  font-size: 12px;
  position: relative;
  z-index: 1;
  top: 15px;
  padding-left: 10px;
}
.disableClear{
  pointer-events: none;
}

.formDatePickerStyles .ui-input__icon:hover:after {
  content: "Clear";
  display: inline-block;
  position: absolute;
  top: 70%;
  left: 50%;
  /* transform: translateX(-50%); */
  background-color: #fff;
  font-size: 14px;
  padding: 2px 5px;
  white-space: nowrap;
  /* opacity: 0; */
  transition: opacity 0.2s ease-in-out;
  z-index: 13;
  border: 1px solid black;
  color: black;
}


.datePickerStyles .ms-TextField-fieldGroup::after,
.datePickerStyles .ms-TextField,
.datePickerStyles .is-active,
.datePickerStyles .ms-TextField-fieldGroup {
  border: none !important;
}

.datePickerStyles .ms-TextField-fieldGroup {
  align-items: center;
}

.datePickerStyles input {
  height: 50px;
  padding-top: 15px;
  padding-left: 10px;
}

.CCDatePicker {
  display: flex;
  align-items: center;
}

.CCDatePicker .clearDateIcon {
  margin-left: -52px;
  margin-top: -34px;
  z-index: 1;
  height: 14px;
}

.datePickerStyles div div span div {
  margin-top: 14px;
  height: 20px;
}

/* div:empty  .clearDateIc? */

.datePickerStyles div div span:has(div:empty) .clearDateIcon {
  margin-top: -30px;
}