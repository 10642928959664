.menu_component__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(-c-primary);
  padding: 13px 13px 13px 16px;
  overflow-x: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu_component__container:hover {
  background-color: #fff !important;
}

.menu_component__container .toggle_submenu_btn {
  color: #000;
  font-size: 1.5em;
  font-weight: bolder;
  cursor: pointer;
  display: flex;
}

.menu_component__container_item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
}

.menu_component__container_item .icon {
  color: #000;
  font-size: 1.6em;
  font-weight: bolder;
  cursor: pointer;
  display: flex;
}

.menu_component__container_item .title {
  font-size: 0.9em;
  padding-left: 1.3em;
  align-self: center;
  text-transform: capitalize;
}
.title_link {
  color: #000;
  text-decoration: none;
  display: flex;
  cursor: pointer;
}

.menu_component__submenu_container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  overflow-x: hidden;
  background-color: #f5f5f5;
  transition: height 0.15s ease-out;
}

.active_menu {
  background-color: #fff;
}

.active_menu .menu_component__container_item {
  font-weight: bold;
}

.active_menu_indicator {
  font-weight: bold;
}
.active_menu_indicator::after {
  content: "";
  height: 3em;
  width: 0.3em;
  background-color: #000;
  position: absolute;
  left: 0;
  border-left: 4px solid black;
}
