.pagenotfound_container {
  text-align: center;
  padding-top: 15%;
}

.pagenotfound_container p {
  margin-bottom: 0px;
}

.pagenotfound_containerHeader {
  font-weight: 700;
  font-size: 14px;
}

.pagenotfound_containerSubtext {
  font-size: 12px;
}

.button_pagenotfound {
  background-color: #4b53bc;
  color: white;
  border: none;
  border-radius: 5px;
  width: 300px;
  margin-top: 20px;
  padding: 9px 12px 10px;
  font-weight: 300;

}

.button_pagenotfound:hover {
  background-color: #333aa0;
}
