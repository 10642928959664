.contactus-close {
  float: right;
  width: 16px !important;
  padding-top: 10px;
  cursor: pointer;
}
.contactUsHeader {
  background-color: #fff;
  border-bottom: 1px solid #d3d3d3;
}
.contactUsBody {
  flex-direction: column;
  margin: 35px 40px 100px 40px;
  width: 60%;
}
.row_wrapper {
  padding-bottom: 25px;
}
.section_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.section_wrapper > div {
  width: 48%;
}
.fullwidth {
  width: 75%;
}
.sectionDropdown .ui-button__content {
  font-weight: 100;
}
.subSectionDropdown .ui-button__content {
  font-weight: 100;
}
/* .formFooter .FooterTechButtons{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
} */
.row_wrapper .ui-input__input {
  padding-right: 0.75rem;
}
.row_wrapper .ui-input__icon {
  display: none;
}

.formFooter {
  bottom: 0;
  height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid lightgrey;
  position: fixed;
  background-color: white;
  padding-right: 1%;
  width: 100%;
  z-index: 9;
  left: 0;
}

@media only screen and (min-width: 320px) and (max-width: 768px){
    .section_wrapper{
        display: block;
    }
    .formDropdownStyles{
        padding-bottom: 1rem;
    }
    .contactUsBody {
        width: 90%;
        margin: 2rem 2rem 2rem 1rem;
    }

    .contactUsHeader{
        top:39px;
        padding: 0 18px;
        min-height: 3.7vh;
      }
      .section_wrapper > div{
        width: 100%;
      }
      .fullwidth{
        width: 100%;
        padding-right: 1.2rem;
        padding-bottom: 16rem;
      }
      .formFooter{
        padding-right: 1.2rem;
      }
}


