.justifyContent {
  text-align: justify;
  margin: 10px;
}
.hyperlink {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}
.list {
  margin-left: 10px;
}
.color-red {
  color: red;
}
.no-bullets {
  list-style-type: none;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.font-size-15 {
  font-size: 15px;
}
.margin-top-10 {
  margin-top: 10px;
}
