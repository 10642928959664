.bulletinCardContainer {
  width: 95%;
}

.bulletinCard {
  width: 100%;
  display: flex;
  gap: 8px;
}

.bulletinCard__avatar {
  width: 40px;
  margin-top: 8px;
}

.bulletinCard__avatar_img {
  width: 100%;
  border-radius: 40px;
  min-width: 30px;
}

.bulletinCard__body {
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  /* box-shadow: -1px -1px 4px -3px black; */
  box-shadow: 1px -2px 4px -3px black;
}

.bulletinCard__card {
  border-top-left-radius: 4px;
}

.my_pinnedCard {
  border-left: 3.5px solid #5b5fc7;
}

.bulletinCard__card #ui-card {
  border: none;
}

.bulletinCard__card #ui-card-body {
  margin-bottom: 0 !important;
}

.bulletinCard__card #ui-card-footer {
  border: 1px solid #d3d3da !important;
}

/* .bulletinCard__card #ui-card:hover {
    box-shadow: none;
  } */
.bulletinCard__card_header {
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  /* position: relative; */
}

.bulletinCard__card__cardwrapper {
  padding: 10px 45px 10px 30px;
}

.bulletinCard__card__pinnedByAndDate {
  display: flex;
  gap: 13px;
  align-items: end;
}

.bulletinCard__card_pinnedBy {
  font-size: 13px;
  font-weight: bold;
}

.bulletinCard__card_dateTime {
  font-size: 12px;
}

.bulletinCard__card_pinIcon {
  font-size: 21px;
}

.bulletinCard__replies {
}

.bulletinCard__textEditor {
}

.reply-btn {
  padding: 10px;
  cursor: pointer;
  font-size: 13px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.reply-btn:hover {
  /* box-shadow: inset 0 0px 1px black; */
  background: #fbfbfb;
}

/* .bulletinCard__card_pinIcon__menu{
    visibility: none;
    position:absolute;
    right:0;
}
.ui-pin-icon:hover .bulletinCard__card_pinIcon__menu{
visibility: visible;

} */

.bulletinCard__card_pinIcon__menu {
  display: flex;
  gap: 8px;
  align-items: center;
}

.bulletinCard__card_pinIcon__menu .ui-menu__item > span:nth-child(2) {
  display: none;
}

.bulletinCard__card_pinIcon__menu .ui-menu__item > span:nth-child(1) {
  padding: 0;
}

.bulletinCard__card_pinIcon__menu .ui-menu__item:first-child {
  padding: 5px;
  text-align: center;
  max-width: max-content !important;
}

.ui-pin-icon {
  cursor: pointer;
}

.bulletinBoardLoaderLayover .ui-loader__indicator {
  position: absolute !important;
  left: 40% !important;
  margin: 0 !important;
}
.popupModal .ms-Dialog-main {
  width: 50%;
  height: auto;
  top: 5%;
  border-radius: 4px;
}
.modalPopupDelete {
  margin: 20px;
}
.loaderOnDelete {
  z-index: 2;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.5);
  min-width: 48%;
  min-height: 14em;
}
.popupHeaderTitle {
  font-weight: 700;
  font-size: 17px;
}
/* .headerStyles > h2{
    font-size: 17px;
    font-weight: 700 !important;
} */
.deletePopupHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
/* Define the keyframes for the animation */
@keyframes shadowAnimation {
  0% {
    box-shadow: -2px 0px 8px black;
  }

  100% {
    box-shadow: 1px -2px 4px -3px black;
  }
}

.element-with-shadow {
  border-radius: 10px;
  animation: shadowAnimation 5s forwards;
}

@media (max-width: 700px) {
  .bulletinCard__card__pinnedByAndDate {
    align-items: center;
    margin-bottom: -10px;
  }

  .bulletinCard__avatar {
    display: none;
  }

  .bulletinCard__card__cardwrapper {
    padding: 10px 15px 10px 15px;
  }
}

@media (min-width: 701px) {
  .bulletinCard__card_pinnedByImg {
    display: none;
  }
}

.bulletinCard__card__cardwrapper .poll-card-header {
  border-top: 1px solid #d3d3da;
  border-right: 1px solid #d3d3da;
  border-left: 1px solid #d3d3da;
}

.bulletinCard__card__cardwrapper .polls-card-body {
  border-bottom: 1px solid #d3d3da;
  border-right: 1px solid #d3d3da;
  border-left: 1px solid #d3d3da;
  margin-bottom: 0 !important;
}
