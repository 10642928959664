.box {
  padding: 10px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 0px black;
  margin-top: 20px;
}

.other h2 {
  font-size: 18px;
  color: #444;
  margin-bottom: 7px;
}

.other a {
  color: #777;
  text-decoration: underline;
  font-size: 14px;
}

.other ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.editor-container {
  border-radius: 2px;
  color: #000;
  position: relative;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  border-radius: 10px;
  background-color: whitesmoke;
}

.editor-container .ui-icon__outline {
  display: block;
}

.editor-container .ui-icon__filled {
  display: none;
}

.editor-container .ui-icon:hover .ui-icon__outline {
  display: none;
}

.editor-container .ui-icon:hover .ui-icon__filled {
  display: block;
}

.toolbar-bottom__icon--active .ui-icon__filled {
  display: block;
}

.toolbar-bottom__icon--active .ui-icon__outline {
  display: none;
}

.toolbar-bottom__icon--active {
  color: #5b5fc7;
}

.editor-inner {
  position: relative;
  border-radius: 10px;
  max-height: 150px;
  border: solid #ebebeb;
  border-width: 0 1px;
  background: #fff;
  border-radius: 0 0 0.4rem 0.4rem;
}

.editor-input:focus {
  border-bottom: 2px solid #5b5fc7;
}

.editor-input {
  resize: none;
  font-size: 15px;
  caret-color: rgb(5, 5, 5);
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: 15px 10px;
  caret-color: #444;
  max-height: 150px;
  overflow-y: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.editor-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 15px;
  left: 10px;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.editor-text-bold {
  font-weight: bold;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-underline {
  text-decoration: underline;
}

.editor-text-strikethrough {
  text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}

.editor-text-code,
pre {
  background-color: rgb(240, 242, 245);
  padding: 1px 0.25rem;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
}

.editor-link {
  color: rgb(33, 111, 219);
  text-decoration: none;
}

.tree-view-output {
  display: block;
  background: #222;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  white-space: pre-wrap;
  margin: 1px auto 10px auto;
  max-height: 250px;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: auto;
  line-height: 14px;
}

.editor-code,
pre {
  background-color: rgb(240, 242, 245);
  font-family: Menlo, Consolas, Monaco, monospace;
  display: block;
  padding: 8px 8px 8px 52px;
  line-height: 1.53;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  tab-size: 2;
  overflow-x: auto;
  position: relative;
}

.editor-code:before {
  content: attr(data-gutter);
  position: absolute;
  background-color: #eee;
  left: 0;
  top: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  color: #777;
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}

/* .editor-code:after {
  content: attr(data-highlight-language);
  top: 0;
  right: 3px;
  padding: 3px;
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
} */

.editor-tokenComment {
  color: slategray;
}

.editor-tokenPunctuation {
  color: #999;
}

.editor-tokenProperty {
  color: #905;
}

.editor-tokenSelector {
  color: #690;
}

.editor-tokenOperator {
  color: #9a6e3a;
}

.editor-tokenAttr {
  color: #07a;
}

.editor-tokenVariable {
  color: #e90;
}

.editor-tokenFunction {
  color: #dd4a68;
}

.editor-paragraph {
  margin: 0;
  margin-bottom: 8px;
  position: relative;
}

.editor-paragraph:last-child {
  /* margin-bottom: 0; */
}

.editor-heading-h1 {
  font-size: 24px;
  color: rgb(5, 5, 5);
  font-weight: 400;
  margin: 0;
  margin-bottom: 12px;
  padding: 0;
}

.editor-heading-h2 {
  font-size: 15px;
  color: rgb(101, 103, 107);
  font-weight: 700;
  margin: 0;
  margin-top: 10px;
  padding: 0;
}

.editor-quote {
  margin: 0;
  margin-left: 20px;
  font-size: 15px;
  color: rgb(101, 103, 107);
  border-left-color: rgb(206, 208, 212);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}

.editor-list-ol {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.editor-list-ul {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.editor-listitem {
  margin: 8px 32px 8px 32px;
}

.editor-nested-listitem {
  list-style-type: none;
}

pre::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

pre::-webkit-scrollbar-thumb {
  background: #999;
}

.emoji {
  color: transparent;
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin: 0 -1px;
}

.emoji-inner {
  padding: 0 0.15em;
}

.emoji-inner::selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.emoji-inner::moz-selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.toolbar {
  display: flex;
  margin-bottom: 1px;
  background: #fff;
  padding: 4px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  vertical-align: middle;
  align-items: center;
  position: relative;
  gap: 5px;
}

.toolbar button.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  vertical-align: middle;
  font-size: 20px;
}

.toolbar button.toolbar-item:disabled {
  cursor: not-allowed;
}

.toolbar button.toolbar-item.spaced {
  margin-right: 2px;
}

.toolbar button.toolbar-item i.format {
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 2px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

.toolbar button.toolbar-item:disabled i.format {
  opacity: 0.2;
}

.toolbar button.toolbar-item.active {
  color: #5b5fc7;
}

.toolbar button.toolbar-item.active i {
  opacity: 1;
}

.toolbar .toolbar-item:hover:not([disabled]) {
  color: #5b5fc7;
}

.toolbar .dividerLine {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
  min-width: 1px;
  height: 22px;
}

.toolbar select.toolbar-item {
  border: 0 !important;
  display: flex !important;
  background: none !important;
  border-radius: 10px !important;
  padding: 8px 20px !important;
  vertical-align: middle !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  font-size: 14px !important;
  color: #777 !important;
  text-overflow: ellipsis !important;
  width: max-content;
}

.toolbar select::-webkit-scrollbar {
  display: none;
}

.toolbar select.code-language {
  text-transform: capitalize;
  width: 130px;
}

.toolbar .toolbar-item .text {
  display: flex;
  line-height: 20px;
  width: 200px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  width: 70px;
  overflow: hidden;
  height: 20px;
  text-align: left;
}

.toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

.toolbar i.chevron-down {
  margin-top: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  user-select: none;
}

.toolbar i.chevron-down.inside {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: -25px;
  margin-top: 11px;
  margin-right: 10px;
  pointer-events: none;
}

i.chevron-down {
  background-color: transparent;
  background-size: contain;
  display: inline-block;
  height: 8px;
  width: 8px;
}

#block-controls button:hover {
  background-color: #efefef;
}

#block-controls button:focus-visible {
  border-color: blue;
}

#block-controls span.block-type {
  background-size: contain;
  display: block;
  width: 18px;
  height: 18px;
  margin: 2px;
}

.dropdown {
  z-index: 3;
  display: block;
  position: absolute;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-width: 100px;
  min-height: 40px;
  background-color: #fff;
}

.dropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  min-width: 268px;
}

.dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
  color: blue;
}

.dropdown .item:first-child {
  margin-top: 8px;
}

.dropdown .item:last-child {
  margin-bottom: 8px;
}

.dropdown .item:hover {
  background-color: #eee;
}

.dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  width: 200px;
}

.dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
}

.link-editor {
  position: absolute;
  z-index: 6;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
}

.link-editor .link-input {
  display: flex;
  width: calc(100% - 24px);
  box-sizing: border-box;
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  background-color: #eee;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.link-editor div.link-edit {
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor .link-input a {
  color: rgb(33, 111, 219);
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
  text-decoration: underline;
}

.link-editor .button {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 2px;
}

.link-editor .button.hovered {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #eee;
}

.link-editor .button i,
.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}

.typeahead-popover {
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  margin-top: 25px;
}

.typeahead-popover ul {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: scroll;
}

.typeahead-popover ul::-webkit-scrollbar {
  display: none;
}

.typeahead-popover ul {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.typeahead-popover ul li {
  margin: 0;
  min-width: 180px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
}

.typeahead-popover ul li.selected {
  background: #eee;
}

.typeahead-popover li {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
}

.typeahead-popover li.active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.typeahead-popover li:first-child {
  border-radius: 8px 8px 0px 0px;
}

.typeahead-popover li:last-child {
  border-radius: 0px 0px 8px 8px;
}

.typeahead-popover li:hover {
  background-color: #eee;
}

.typeahead-popover li .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

.typeahead-popover li .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.mentions-menu {
  width: 250px;
}

span[data-lexical-mention] {
  background-color: rgba(24, 119, 232, 0.2);
}

.emoji-menu {
  /* width: 265px; */
  max-height: 225px;
  overflow-y: auto;
}

.emoji {
  color: transparent;
  caret-color: rgb(5, 5, 5);
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin: 0 -1px;
}

.emoji-inner {
  padding: 0 0.15em;
}

.emoji-inner::selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.emoji-inner::moz-selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.emoji-menu ul {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  list-style: none;
  padding: 0px;
}

.emoji-menu ul li {
  min-width: 10px;
  cursor: pointer;
}

.emoji-menu ul li:hover {
  background-color: gray;
  border-radius: 10px;
}
.emoji-menu-reply {
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  max-width: 350px; /* Adjust as needed */
  max-height: 300px; /* Adjust as needed */
  overflow: auto;
  padding-bottom: 0; /* No padding at the bottom */
  margin-bottom: 0; /* No margin at the bottom */
}

.emoji-menu-reply ul {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* At least 7 emojis per line */
  gap: 1px;
  list-style: none;
  padding: 0px; /* Reduced padding */
  font-size: medium;
  box-sizing: border-box; /* Include padding and gap in element's total width and height */
  padding-bottom: 0; /* No padding at the bottom */
  margin-bottom: 0; /* No margin at the bottom */
}

.emoji-menu-reply ul li {
  padding: 0px 0px 0px 4px; /* Reduced padding */
  line-height: 2; /* Adjust line-height to align with your emoji */
  height: auto; /* Ensures the height fits the content */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
  cursor: pointer;
  min-width: 30px; /* Adjust this to match minmax size in grid-template-columns */
  cursor: pointer;
}

.emoji-menu-reply ul li:hover {
  background-color: gray;
  border-radius: 10px;
}

.toolbar-bottom {
  background-color: #f5f5f5;
  margin-top: 5px;
  position: relative;
  display: flex;
  gap: 10px;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
}

.toolbar-bottom__left,
.toolbar-bottom__right {
  display: flex;
  gap: 10px;
  align-items: center;
}

.editor-container__wrapper {
  box-shadow: 0 4px 4px -5px black;
}

.toolbar-bottom__icon {
  font-size: 22px;
  cursor: pointer;
}

.toolbar-bottom__icon:hover {
  color: #5b5fc7;
}

.toolbar-bottom .ui-menu__item {
  cursor: default !important;
}

.toolbar-bottom .ui-menu__itemwrapper {
  background: #fff !important;
}

.toolbar-bottom .ui-menu__itemcontent {
  padding: 0 !important;
  margin: 0 !important;
}

.trash-icon {
  position: absolute;
  right: 25px;
  cursor: pointer;
}

.type_msg {
  padding: 6px;
  padding-left: 17px;
  border-radius: 0px;
}

.type_msg p {
  font-size: 14px;
}

.edit-cursor {
  cursor: pointer;
}
