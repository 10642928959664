.downtime-page {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
    gap: 10px;
}

.downtime-page__image {
    mix-blend-mode: multiply;
    filter: contrast(1.1);
    height: 150px !important;
}

.downtime-page__end-session-msg {
    font-size: 17px;
    font-weight: 600;

}

.downtime-page__login-btn {
    font-size: 16px;
    padding: 10px 20px;
    background: black;
    color: white;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
}
.under-maintenance-msg{
    text-align: center;
    line-height: 1.5;
    }