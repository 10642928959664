.linkPreviewWrapper {
  display: flex;
  background-color: #fff;
  /* height: 100px; */
  position: relative;
  margin: 0 20px 10px 20px;
}

.linkImage {
  padding: 10px;
  width: 100%;
  max-width: 150px;
  height: auto;
}
.linkTitleUrl {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  margin-right: 10px;
}
.linkTitle {
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.linkUrl {
  margin-top: 10px;
  font-size: 10px;
}

@media screen and (max-width: 600px) {
  .linkTitle,
  .linkUrl {
    font-size: 7px;
  }
}
.linkPreviewCloseBtn {
  position: absolute;
  right: 10px;
  cursor: pointer;
  padding: 10px;
}

.showLinkPreview {
  font-size: 40px;
  position: relative;
  padding: 10px 10px 0 10px;
  cursor: pointer;
}

.toolTip {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  /* width: fit-content; */
  background-color: rgb(243 242 241);
}
.toolTip p {
  /* position: absolute; */
  padding: 10px;
  background-color: rgb(243 242 241);
  color: #000;
  width: fit-content;
  border-radius: 5px;
  font-size: 10px;
  margin-left: -10px;
  margin-top: 10px;
  visibility: hidden;
  cursor: pointer;
  border: 1px solid #000;
}

.toolTip:hover p {
  visibility: visible;
}

@media only screen and (min-width: 320px) and (max-width: 389px){
  .linkPreviewWrapper{
    flex-wrap: wrap;
  }
   
}

@media only screen and (min-width: 390px) and (max-width: 580px){
  .linkPreviewCloseBtn{
    right: 0px !important;
  }
  .linkPreviewWrapper{
    flex-wrap: wrap;
  }
}