.reusableloader_container {
  padding-top: 20%;
}
.reusableloader_container_pn {
  padding-top: 10%;
  padding-bottom: 10%;
}
.pollsLoader {
  padding-top: 0% !important;
}
.ui-loader__indicator > svg > circle:first-of-type {
  stroke: rgb(255, 255, 255);
}

.appbody_component_loader {
  height: 100vh;
  width: 100%;
}

/* .is_component_body {
    height: 100vh;
    width: 100%;
    padding: 0px 5rem 0px 60%;
} */

.is_component_body {
  height: 100vh;
  width: 100%;
  padding: 0px 0px 0px 0px;
}

.ui-loader {
  margin-left: auto;
  margin-right: auto;
}

.bookmarkLayover {
  margin-left: 20% !important;
  margin-top: 10% !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.pollsLoader {
  padding-top: 0% !important;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .mail_component_loader {
    min-width: 16rem;
  }
}
