.commingsoon_container {
  text-align: center;
  padding-top: 15%;
  padding-bottom: 10%;
}

.commingsoon_container p {
  margin-bottom: 0px;
}

.commingsoon_containerHeader {
  font-weight: 700;
  font-size: 14px;
}

.commingsoon_containerSubtext {
  font-size: 12px;
}
