.dropdown-container-multiSelect {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 0;
    max-height: 275px;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    background: white;
    z-index: 1;
    width: 100%;
}

.dropdown-container-multiSelect  {
    display: block;
}

.option-container-multiSelect {
    width: 100%;
    font-size: 14px;
    position: relative;
    text-align: left;
    transition: all 1s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    height: 45px;
    overflow-y: hidden;
    line-height: 20px;
}

.option-container-open-multiSelect {
    height: auto;
    transition: all 1s cubic-bezier(0.08, 1.09, 0.32, 1.275);
}

.option-children-hidden-multiSelect {
    /* padding: 15px; */
    visibility: hidden;
    opacity: 0;
    overflow: auto;
    transition: all 0.2s ease-in;
}

.option-children-visible-multiSelect {
    visibility: visible;
    opacity: 1;
    transition: all 0.8s ease-in;
}

.option-label-multiSelect {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* cursor: pointer; */
    padding: 10px;
    /* margin-left: 20px; */
    position: relative;
    z-index: 0;
}

.option-label-input-multiSelect {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    /* cursor: pointer; */
    padding: 10px;
    position: relative;
    /* margin-left: 20px; */
}

.disabled-option-multiSelect,
.disabled-option-multiSelect:hover {
    background: #fff;
    color: #818384;
    cursor: default;
    transition: none;
}

.option-arrow-multiSelect {
    font-size: 25px;
    cursor: pointer;
}

.option-checkbox-multiSelect {
    height: 18px;
    width: 18px;
    cursor: pointer;
}

.selected-option-container-multiSelect {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    max-height: 180px;
    overflow: auto !important;
}
.overflowValues-multiSelect{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30ch;
    overflow-wrap: break-word;
    word-break: break-all;
    white-space: normal;
}
.overflowValuesCharcount-multiSelect{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 35ch;
    overflow-wrap: break-word;
    word-break: break-all;
    white-space: normal;
}

.selected-option-pill-multiSelect {
    color: rgba(13, 110, 253, 1);
    margin-top: 10px;
    max-width: 98%;
    width: max-content;
    background-color: rgba(170, 210, 236, 0.2);
    border-radius: 10px;
    font-size: 12px;
    display: flex;
    gap: 2px;
    flex-direction: row;
    height: 25px;
    align-items: center;
}

.selected-option-pill-multiSelect span {
    margin-right: 5px;
}

.remove-button-multiSelect {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: rgba(13, 110, 253, 1);
    ;
    font-size: 20px;
}

.option-search-box-multiSelect {
    width: 100%;
    height: 2.1rem;
    padding: 10px;
    border-radius: 5px 5px 0 0;
    margin: 7px 0;
    background-color: #f6f6fa;
    border: none !important;
    border-bottom: 1px solid black !important;
}

.option-search-box-multiSelect:focus-visible {
    outline: none;
}

.multiselect_error_parent {
    border-bottom: 0.03vh solid;
    border-bottom-color: rgb(196, 49, 75);
}

.multiselectdivlabel_parent {
    position: relative;
    bottom: 6px;
}

.noOption-class-multiSelect {
    background-color: #f6f6fa;
    height: 2.5rem;
    padding: 5px;
}

.nested-span-checkbox-multiSelect {
    display: flex;
    align-items: center;
    gap: 10px;
}


.TNURemoveBtn-multiSelect {
    cursor: text !important;
}

.TNUselected-option-pill-multiSelect {
    cursor: text;
    background-color: #939191;
}

.multiselect-group-parent {
    position: relative;
}

.multiselect-group-parent:focus {
    outline: none;
}