.popupContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.topContent {
  padding: 1rem;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
}

.ms-Modal-scrollableContent {
  overflow-y: initial !important;
}
.topborder {
  border-bottom: 1px solid lightgrey;
}

.dialogStyles > h2 {
  font-size: 14px;
}

.calendarPopupStyle {
  /* border-radius: 2px !important; */
  border-radius: 0px !important;
  display: flex !important;
  flex-direction: column;
  padding: 0rem !important;
  max-height: 85% !important;
  height: max-content !important;
}
.privacyPopupStyle {
  border-radius: 0px !important;
  display: flex !important;
  flex-direction: column;
  padding: 0rem !important;
  max-height: 90% !important;
  height: max-content !important;
}
.manageTeamPopupStyle {
  border-radius: 0px !important;
  display: flex !important;
  flex-direction: column;
  padding: 0rem !important;
  width: 35rem !important;
  max-height: 85% !important;
  height: max-content !important;  
}
.sharePopupStyle {
  width: 25rem !important;
  padding: 0rem !important;
  border-radius: 0px !important;
  max-height: 85% !important;
  height: max-content !important;
}
.copyLinkPopupStyle {
  padding: 0rem !important;
  max-width: 25rem !important;
  border-radius: 0px !important;
  max-height: 85% !important;
  height: max-content !important;
}
.sendLinkPopupStyle {
  display: flex !important;
  flex-direction: column;
  padding: 0rem !important;
  /* width: 30rem !important; */
  border-radius: 0px !important;
  max-height: 85% !important;
  height: max-content !important;
}
.popupHeader {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.popupHeader span {
  font-weight: 500;
}

.closeIconPopup {
  cursor: pointer;
  /* padding:10px; */
}

.closeIconPopup svg {
  height: 1.5rem;
  width: 1.5rem;
  padding-bottom: 0.2rem;
}

.btncontainer {
  display: flex;
  flex-direction: row-reverse;
}

.popupBody {
  flex: 2;
  min-height: 5rem;
  padding: 1rem;
  
 
}

.popupFooter {
  flex: 1;
  padding: 0.5rem 1.5rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.scrollableContent-119 {
  overflow-y: unset !important;
}

.popupError svg {
  display: inline-block;
}

.popupError p {
  color: #c4314b;
  text-align: right;
  margin-left: auto;
  margin-bottom: 1rem;
  width: 70%;
}
.ui-dialog__header {  
  font-size: 17px !important;
  font-weight: 700 !important;
}

.openInTeams {
  padding: 0px 8px !important;
  background-color: rgb(91, 95, 199) !important;
  color: white !important;
  border: 1px solid rgb(91, 95, 199) !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  transition: all 0.1s;
  height: 34px;
  font-family: inherit;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
}

.openInTeams:disabled {
  background-color: #ebebeb !important;
  color: rgb(210, 208, 206) !important;
  border: 1px solid rgb(243, 242, 241) !important;
}

@media only screen and (min-width: 239px) and (max-width: 420px) {
  .openInTeams{
    padding: 0px 4px !important;
    font-size: 12px;
    height: auto;
    width: calc(100% - 185px);
  }
}