.notification_icon {
  /* width: 1.5rem;
  position: relative; */
  cursor: pointer;
}

.notification-text {
  display: inline-flex;
  margin-left: -3px;
}

.notification-action-div {
  display: flex;
  align-items: center;
  /* gap: 7px; */
  padding: 0;
  /* margin-right: -5px; */
  margin-top: 2px;
}

.notification-action-flex {
  display: flex;
}

.notification_panel_sidebar .ms-Panel-main {
  min-width: 400px;
}

@media screen and (max-width: 400px) {
  .notification_panel_sidebar .ms-Panel-main {
    min-width: 380px;
  }
}
