.ui-tooltip__content {
  padding: -10px; /* adjust as needed */
  pointer-events: none; /* ensures the padded area does not capture mouse events */
  z-index: 18 !important;
}

.ui-tooltip__content > div:first-child {
  /* left: -55% !important; */
  /* float:left */
  /* transform: translate3d(15px, 1px, 0px) !important; */
  pointer-events: auto; /* ensures the tooltip content captures mouse events */
}

.ui-tooltip__content > div:nth-child(2) {
  overflow-wrap: break-word;
  word-break: break-all;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  line-break: strict;
  pointer-events: auto; /* ensures the tooltip content captures mouse events */
}
