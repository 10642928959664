.badge {
  font-size: 0.7rem;
  color: #000;
  font-weight: 500;
  border: 2px solid rgb(124, 139, 108);
  border-radius: 2em;
  /* margin: 0 10px; */
}

@media screen and (max-width: 900px) {
  .badge {
    margin: 0 10px;
  }
}
@media screen and (min-width: 1160px) {
  .badge {
    margin: 0 10px;
  }
}
