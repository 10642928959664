/* .useronboarding-welcomepage {
  background-image: url("../../assets//WelcomeUI.jpg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.onboarding-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2% 6%;
  background-color: #2c2f3bbf;
  color: white;
}

.onboarding-form__heading {
  font-size: 25px;
  font-weight: 600;
  display: flex;
  padding-bottom: 10px;
}

.onboarding-form__label {
  font-size: 17px;
  padding-bottom: 15px;
}

.onboarding-form__input {
  display: flex;
  flex-direction: column;
  gap: 18px;
  font-size: 13px;
}

.onboarding-form__input input {
  width: 100%;
  padding-left: 8px;
  background-color: white;
  font-size: 14px;
  color: #747480;
  padding-top: 2px;
  font-weight: 600;
}

.onboarding-form__input label {
  padding: 5px 25px 0 8px;
  font-size: 14px;
  color: #747480;
}

.onboarding-form__input div {
  background: white;
  color: black;
}

.onboarding-form__btn {
  padding: 12px;
  color: black;
  font-weight: bold;
}

.yellow-btn {
  background: #ffe600;
  margin-top: 45px;
  border: 0px;
  margin-bottom: -8px;
}

.white-btn {
  background: white;
  border: 0px;
} */

.login-container {
  width: 100%;
  /* height: 100%; */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  position: absolute;
}
.login-form {
  width: 430px;
  margin: 0 auto;
  border: 1px solid #ddd;
  padding: 1.5rem 5rem 1.5rem 5rem;
  background: #ffffff;
}
.form-input {
  background: #fafafa;
  border: 1px solid #eeeeee;
  padding: 2px;
  width: 100%;
}
.form-group {
  margin-bottom: 1.2rem;
}
.form-group input {
  width: 100%;
  padding-left: 8px;
  background-color: #fafafa;
  color: #747480;
  padding-top: 2px;
  font-size: 14px;
  font-weight: 600;
}
.form-group label {
  padding: 5px 25px 0 8px;
  font-size: 14px;
  color: #747480;
}
.form-header {
  text-align: left;
  margin-bottom: 2rem;
}
.form-header_image {
  margin-bottom: 1.5rem;
}
.header-image {
  height: 4.5rem;
}
.form-header_heading {
  font-size: 25px;
  font-weight: 700;
  display: flex;
}
.form-group_label {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 1.2rem;
}
.form-group_label2 {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 1.2rem;
}
.form-button {
  border: 1px solid;
  color: black;
  padding: 12px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
}
.yellow-btn {
  background: #ffe600;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}
.white-btn {
  background: white;
}
.additional-class > label,
.additional-class div > input,
.additional-class,
.additional-class .ui-dropdown__container ,
.additional-class .ui-dropdown__container:hover{
  background-color: white;
}
.disabled-btn{
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  background-color: #ebebeb;
}