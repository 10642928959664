.breadcrumbItem {
  font-weight: 400;
  color: #424242;
  font-size: 14px;
  text-decoration: none;
  white-space: nowrap;
}

.lastItem {
  font-weight: 700;
  color: #242424;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

p {
  margin: 0;
}

@media only screen and (min-width: 390px) and (max-width: 680px) {
  .lastItem {
    width: 120px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 389px) {
  .lastItem {
    width: 68px;
  }
}
