/* .ui-radiogroup__item{
    flex-wrap: wrap;
}
.ui-radiogroup__item__label{
    width: 30%;
} */

.spnError {
  font-size: 12px;
}
.errorindicator {
  border: 1px solid rgb(196, 49, 75) !important;
}
.radioButtonsStyles .ui-radiogroup__item__indicator {
  color: black;
}
.radioButtonsStyles .ui-radiogroup__item,
.radioButtonsOptions .ui-radiogroup__item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.radioButtonsStyles .ui-radiogroup {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;
}
.radioButtonsStyles .radioButtonDisableStyles .ui-radiogroup {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;
}
.radioButtonsOptions .ui-radiogroup__item__label {
  word-break: break-word;
}
.radioButtonsOptions {
  width: 100% !important;
}
.radioButtonsOptions .ui-radiogroup__item{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
/* .radioButtonsOptions .ui-radiogroup__item__indicator{
  margin-top:5px;
} */
