.contentCurationHeader {
  background-color: #fff;
  border-bottom: 1px solid #d3d3d3;
  padding: 15px 20px;
  min-height: 7.7vh;
  /* top: 6vh; */
  height: auto;
  z-index: 5;
}

.notifications-close {
  float: right;
  width: 16px !important;
  padding-top: 10px;
  cursor: pointer;
}

.common_wrapper {
  padding-bottom: 30px;
}

/* .alertPadding {
  padding-bottom: 15px;
} */

.disableRadioImages {
  opacity: 0.5;
  cursor: default;
}

.contentCurationBody {
  flex-direction: column;
  margin: 40px 40px 100px 20px;
  width: 75%;
}

/* .srcDate {
  width: 36%;
  float: left;
}
.srcTime {
  width: 34%;
  float: left;
}
.srcLocation {
  width: 30%;
  float: left;
} */

.srcDate .formLabelStyles {
  margin-bottom: -21px;
}

.srcDate {
  padding-bottom: 0px;
}

.additionalMessage {
  font-size: 12px;
}

.formFooter {
  bottom: 0;
  left: 0;
  height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid lightgrey;
  position: fixed;
  background-color: white;
  padding-right: 1%;
  width: 100%;
  z-index: 9;
  left: 0;
}

/* .fullwidth {
  width: 75%;
} */

.saveForm {
  padding: 11px, 36px, 11px, 36px;
  width: 165px;
  height: 44px;
  background-color: #ffe600;
  color: #2e2e38;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #2e2e38 !important;
  transition: all 0.1s;
}

.formFooter .cancelForm {
  padding: 11px, 36px, 11px, 36px;
  width: 80px;
  /* height: 44px; */
  background-color: white;
  color: #2e2e38;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  transition: all 0.1s;
  margin: 0 1rem;
  border: 1px solid #2e2e38;
}

.urlContent .formLabelStyles,
.uploadContent .formLabelStyles {
  width: 30%;
}

.authTitle {
  width: 38%;
  float: left;
}

.authImage {
  width: 60%;
  float: right;
}

.authImage .fileUploadTextBox {
  width: 70%;
}

.authImage .fileUploadTextBox .textInputStyles {
  width: 100%;
}

.authImage .fileUploadTextBox .textInputStyles input {
  width: 100%;
}

.cardImage .fileUploadTextBox {
  width: 85%;
}

.cardImage .fileUploadTextBox .textInputStyles {
  width: 100%;
}

.cardImage .fileUploadTextBox .textInputStyles input {
  width: 100%;
}

.cardImage {
  padding-bottom: 0px;
}

.srcLocation .ui-radiogroup {
  justify-content: space-between;
  /* width: 50%; */
}

.tileDownloadCheckbox .formLabelStyles {
  display: none;
}

/* .srcdate .textbox_error input{
  border-bottom: .5px solid !important;
  border-bottom-color:rgb(196, 49, 75) !important ;
}
  */
.radioButtonsStyles,
.ui-radiogroup__item__indicator {
  color: black;
}

.ui-radiogroup__item {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
}

.radioButtonsDisableStyles {
  color: darkgray;
}

.formFileUploadStyles .fileUploadTextBox input::placeholder {
  color: #484644 !important;
}

.contentCurationBody
  .contentCategory
  div
  .multiselect-container
  .searchWrapper
  input,
.contentCurationBody
  .contentCategory
  div
  .multiselect-container
  .searchWrapper
  input::placeholder {
  color: #484644 !important;
  line-height: 1.6 !important;
  overflow: auto !important;
}

.commonImageStyles {
  object-fit: cover;
  width: 75px;
  height: 70px !important;
  border-radius: 10px;
}

@media only screen and (min-width: 390px) and (max-width: 768px) {
  .tileDefaultImgClass .ui-radiogroup {
    flex-wrap: wrap;
  }

  .contentCurationHeader {
    top: 36px;
    padding: 15 16px;
    min-height: 4.8vh;
  }

  .formLabelStyles {
    width: 100% !important;
  }

  .srcLocation .ui-radiogroup {
    width: 100% !important;
  }

  .contentCurationBody {
    width: 92% !important;
    margin: 20px 40px 100px 19px;
  }

  .formFooter {
    padding-right: 5%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 389px) {
  .tileDefaultImgClass .ui-radiogroup {
    flex-wrap: wrap;
  }

  .contentCurationHeader {
    top: 39px;
    padding: 0 16px;
    min-height: 4.8vh;
  }

  .formLabelStyles {
    width: 100% !important;
  }

  .srcLocation .ui-r .formFooter {
    padding-right: 5%;
  }

  adiogroup {
    width: 100% !important;
  }

  .contentCurationBody {
    width: 90% !important;
    margin: 12px 40px 100px 19px;
  }

  .formFooter {
    padding-right: 5%;
  }
}

.TNUContentForm .uploadContent,
.TNUContentForm .urlContent,
.TNUContentForm .srcLocation {
  display: none !important;
}

.broadcastingClass .formLabelStyles {
  display: none;
}

/* .dialogStyles .ui-dialog__footer button {
  padding: 0px 16px !important;
  background-color: rgb(255, 255, 255) !important;
  color: #2e2e38 !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  border: 1px solid #2e2e38 !important;
  transition: all 0.1s !important;
  height: 34px !important;
  font-family: inherit !important;
  text-align: center !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}

.dialogStyles .ui-dialog__footer button:last-child {
  background-color: #ffe600 !important;
}

.dialogStyles .ui-dialog__footer {
  padding-bottom: 20px;
} */

.LocalContentPopup .ui-dialog__content {
  /* background-color: transparent !important;
     opacity: 1 !important; */
  min-height: auto !important;
}

.localPopupheader > h2 {
  margin-bottom: 20px;
  font-size: 1.25rem;
  font-weight: 600 !important;
}

.container {
  display: flex;
  margin: 0px 20px 24px;
  padding: 0px 0px 15px;
  max-width: -webkit-fill-available;
}

.containerBorder {
  border: 1px solid #d1d1d1;
  border-radius: 5px;
}

.column {
  flex: 50%;
  padding: 10px 30px 10px 30px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.control {
  margin-bottom: 10px;
  padding: 10px;
}

.row > * {
  width: 50%;
}

/* .librarySearchTile{
     display: flex;
     border: 1px solid #D1D1D1;
     border-radius: 5px;
     margin-bottom: 10px;
     margin-top: 10px;
   } */
.container .datePlaceHolderClass {
  display: none;
}

.container .CCDatePicker {
  display: block;
  align-items: normal;
  height: 40px;
  position: relative;
}

.container .CCDatePicker .clearDateIcon {
  z-index: 1;
  position: absolute;
  height: 14px;
  top: 14px;
  right: 32px;
  margin: auto;
}

.container .datePickerStyles input {
  height: 2.5rem;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
}
.container .datePickerStyles .ms-TextField-fieldGroup {
  height: 2.5rem;
}

.MultiCheckbox.ui-checkbox {
  align-items: center;
}

.MultiCheckbox[aria-checked="true"] .ui-checkbox__indicator {
  background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' role='presentation' style='background-color: black; padding: 2px;' focusable='false' viewBox='8 8 22.5 22.5'><g><path fill='%23fff' d='M23.5 11.875a.968.968 0 0 1-.289.711l-8.25 8.25c-.192.193-.43.289-.711.289s-.519-.096-.711-.289l-4.75-4.75a.965.965 0 0 1-.289-.711c0-.125.027-.25.082-.375s.129-.234.223-.328a.953.953 0 0 1 .695-.297c.135 0 .266.025.391.074.125.05.231.121.32.215l4.039 4.047 7.539-7.547a.886.886 0 0 1 .32-.215c.125-.049.255-.074.391-.074a1.004 1.004 0 0 1 .922.625.97.97 0 0 1 .078.375z' /></g></svg>") !important;
  border-left-color: rgb(34, 15, 15) !important;
  border-right-color: black !important;
  border-top-color: black !important;
  border-bottom-color: black !important;
}

.MultiCheckbox[aria-checked="true"] .ui-checkbox__indicator:hover {
  background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' role='presentation' style='background-color: black; padding: 2px;' focusable='false' viewBox='8 8 22.5 22.5'><g><path fill='%23fff' d='M23.5 11.875a.968.968 0 0 1-.289.711l-8.25 8.25c-.192.193-.43.289-.711.289s-.519-.096-.711-.289l-4.75-4.75a.965.965 0 0 1-.289-.711c0-.125.027-.25.082-.375s.129-.234.223-.328a.953.953 0 0 1 .695-.297c.135 0 .266.025.391.074.125.05.231.121.32.215l4.039 4.047 7.539-7.547a.886.886 0 0 1 .32-.215c.125-.049.255-.074.391-.074a1.004 1.004 0 0 1 .922.625.97.97 0 0 1 .078.375z' /></g></svg>") !important;
  border-left-color: black !important;
  border-right-color: black !important;
  border-top-color: black !important;
  border-bottom-color: black !important;
}

.MultiCheckbox[aria-disabled="true"] .ui-checkbox__indicator {
  background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' role='presentation' style='background-color: black; padding: 2px;' focusable='false' viewBox='8 8 22.5 22.5'><g><path fill='%23fff' d='M23.5 11.875a.968.968 0 0 1-.289.711l-8.25 8.25c-.192.193-.43.289-.711.289s-.519-.096-.711-.289l-4.75-4.75a.965.965 0 0 1-.289-.711c0-.125.027-.25.082-.375s.129-.234.223-.328a.953.953 0 0 1 .695-.297c.135 0 .266.025.391.074.125.05.231.121.32.215l4.039 4.047 7.539-7.547a.886.886 0 0 1 .32-.215c.125-.049.255-.074.391-.074a1.004 1.004 0 0 1 .922.625.97.97 0 0 1 .078.375z' /></g></svg>") !important;
  border-left-color: rgb(34, 15, 15) !important;
  border-right-color: black !important;
  border-top-color: black !important;
  border-bottom-color: black !important;
  opacity: 0.3;
}

.librarySearchForm .optionListContainer.displayBlock {
  height: auto;
  max-height: 15.625rem;
}

.librarySearchForm .multiselectClass .searchWrapper.search-wrapper {
  width: 100%;
  background-color: #f6f6fa;
  border: 0;
  height: 2.5rem;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 0.03vh solid #2e2e38;
  border-radius: 0px;
}

.librarySearchForm .multiselectClass {
  height: initial;
  border-bottom: none;
}

.librarySearchForm .multiselectClass .chip {
  background: black;
  border-radius: 20px;
  color: #fff;
  font-size: 16px;
  max-width: 85%;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
}

.librarySearchForm .multiSelectContainer input {
  margin-top: 0;
}

.librarySearchForm .dropdownStyles .ui-button {
  height: 2.5rem;
}

.librarySearchForm .multiselectClass .optionContainer .option .checkbox {
  height: 14px;
  width: 14px;
}

.librarySearchForm
  .multiselectClass
  .optionListContainer
  .optionContainer
  .option {
  gap: 4px;
}

.librarySearchForm .dropdownStyles .ui-box.ui-dropdown__item__content {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.librarySearchForm
  .dropdownStyles
  .ui-list.ui-dropdown__items-list[aria-hidden="false"] {
  max-height: 15.625rem;
  box-shadow: 0px 0px 0px 0.8px #ccc;
}

.librarySearchForm #multiselectContainerReact {
  width: 100%;
  left: initial;
}

.librarySearchForm .formTextInputStyles .ui-input__input {
  height: 2.5rem;
}

.FooterSplitButtons .ui-button,
.FooterSplitButtons .ui-splitbutton__toggle {
  background-color: #ffe600;
}
.FooterSplitButtons .ui-splitbutton__toggle:disabled,
.FooterSplitButtons .ui-button:disabled {
  background-color: #f0f0f0;
  color: #0000006e;
}
.FooterSplitButtons .SearchButton {
  background-color: #ffe600;
  height: 100%;
  font-size: 16px;
  padding: 8px 14px;
}
.FooterSplitButtons .SearchButton .ui-button__content,
.FooterSplitButtons .SearchButton .ui-box {
  font-size: 16px;
}
.FooterSplitButtons .SearchButton .ui-box {
  margin-right: 8px;
}

.FooterSplitButtons div[disabled] {
  display: block;
}

#LibrarySearch {
  margin-bottom: 80px;
}
#LibrarySearch .multi_select_checkBox {
  gap: 24px;
}
#LibrarySearch .multi_select_checkBox .ui-box {
  margin-top: 0;
}
.multi_select_checkBox > p {
  font-weight: 700 !important;
  color: #000;
}

.FooterSplitButtons .ui-button,
.FooterSplitButtons .ui-splitbutton__toggle {
  background-color: #ffe600;
}

.SearchData {
  background-color: #ffe600;
}
.dateControls {
  width: 50%;
}
.infoIconDisplay {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 3px;
}
@media screen and (min-width: 320px) and (max-width: 803px) {
  .containerMobileScreen .column {
    width: 50%;
  }

  .containerMobileScreen .row {
    display: block;
  }

  .containerMobileScreen .control.librarySearchForm {
    width: 100%;
  }
  #LibrarySearch .multi_select_checkBox {
    display: block;
  }
  #LibrarySearch .container {
    margin-bottom: 0px;
    display: block;
  }
  #LibrarySearch .container.containerMobileScreen {
    margin-bottom: 14px;
  }

  #LibrarySearch .common_container_subtitle_internalApp {
    height: 4rem;
  }
  #LibrarySearch .multiselectClass {
    margin-top: 4px;
  }
  .librarySearchForm .ui-box {
    text-overflow: ellipsis;
  }
}

.mobileContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.mobileRow {
  display: block;
  margin-bottom: 1.2rem;
}
.staticDropdowns {
  width: 50%;
}
.mobileMultiSelect {
  width: 100%;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
}

.librarySearchForm .search-filter-input input {
  background-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
