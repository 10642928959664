.alertStyles {
  width: 100%;
  margin: 10px 0px;
  font-size: 14px;
  /* padding-bottom: 12px; */
}
.alertStyles svg {
  margin-right: 6px;
  font-size: larger;
}
.ui-alert__content .hk {
  font-weight: 600 !important;
}

.ui-alert__body {
  /* opacity: 1 !important;
  background-color: inherit !important; */
  padding-top: 5px !important;
}

.ui-dropdown__item__content {
  width: 100%;
}

.ui-dropdown__item__header {
  width: 100%;
}
