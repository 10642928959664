.tat-div-image {
  text-align: -webkit-center;
}

.ui-dialog {
  /* padding-top: 10px !important; */
  /* padding-bottom: 10px !important; */
}

.tat-header-title {
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 5px;
}

.tat-next-button {
  color: white !important;
  background-color: black !important;
  float: right;
  width: auto !important;
}

.tat-prev-button {
  float: left;
}

.takeAtourDialoag {
  max-height: 94vh !important;
}

.tat-content ul {
  list-style-type: disc;
  margin-left: 30px;
}

.tat-content li {
  margin-bottom: 2px;
}
