.nousergroups_container {
    text-align: center;
    padding-top: 15%;
    padding-bottom: 10%;
}

.nousergroups_container p {
    margin-bottom: 0px;
}

.nousergroups_containerHeader {
    font-weight: 700;
    font-size: 14px;
}

.nousergroups_containerSubtext {
    font-size: 12px;
    text-align: center;
    white-space: pre-line;
}