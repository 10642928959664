.panelControls {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 14px 16px;
}
.panelControls .ui-button:disabled {
  color: #747480;
}
.panelControls .datePickerStyles input {
  height: -webkit-fill-available !important;
  padding-top: 0px !important;
}
.panelControls .dropdownStyles {
  border: 1px solid #c4c4cd !important;
  border-radius: 4px !important;
}
.panelControls .dropdownStyles .ui-dropdown__selected-items {
  border-radius: 4px !important;
}
/* .panelControls .ui-button__content svg{
    margin-bottom: 3px;
} */
.panelControls .formDropdownStyles .ui-dropdown__item__content {
  font-size: 14px;
  color: #464844;
}
.panelView .ms-Panel-content {
  padding: 14px !important;
}
.panelView .ms-Panel-headerText {
  font-size: 18px;
  font-weight: 600;
}
.panelViewPopup {
  position: absolute !important;
  overflow: hidden auto !important;
  inset: 0px 0px 0px auto;
  width: 32% !important;
  right: 0;
  top: 50px;
  border-radius: 0 !important;
  padding: 0px !important;
}
.panelViewPopupHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 14px 16px;
  align-items: center;
  border-bottom: 1px solid #e1e1e6;
}
.placeHolderSearchIcon {
  display: inline;
  margin-right: 5px;
  color: #747480;
  font-weight: bold;
}
.closeParamsPanel {
  cursor: pointer;
}
.panelViewPopupHeader p {
  font-weight: 700;
}
.panelViewPopup .ui-dialog__content {
  grid-row: auto;
}
.panelViewPopupHeader > p {
  font-size: 16px;
  font-weight: 600 !important;
}
.panelViewPopupHeaderContent {
  display: flex;
  flex-direction: column;
}
.panelViewPopup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px;
}

.panel-dialog-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.panelViewPopupFooter .FooterTechButtons {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 14px 16px;
  width: 100%;
  border-top: 1px solid #e1e1e6;
  background-color: #ffffff;
}
.sectionPlaceHolder {
  font-size: 14px;
  font-weight: 500;
  color: #747480;
}
.panelControls .ui-dropdown__container:focus-within,
.panelControls .ui-dropdown__container:focus {
  border-bottom: 1px solid rgb(196, 196, 205) !important;
}
