*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fff;
}

html,
body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100vh;
}

.teamAppContainer {
  display: grid;
  width: 100%;
  height: 100%;
  font-size: 16px;
  transition: 0.5s;
  justify-content: space-between;
}

.teamAppContainer_lg {
  grid-template-columns: 20% 80%;
}

/* .teamAppContainer_lg {
  grid-template-columns: 260px 1fr;
} */

@media screen and (min-width: 901px) {
  .teamAppContainer_lg {
    grid-template-columns: 25% 75%;
  }
}

@media screen and (min-width: 1001px) {
  .teamAppContainer_lg {
    grid-template-columns: 24% 76%;
  }
}

@media screen and (min-width: 1150px) {
  .teamAppContainer_lg {
    grid-template-columns: 21% 79%;
  }
}

@media screen and (min-width: 1300px) {
  .teamAppContainer_lg {
    grid-template-columns: 18% 82%;
  }
}

@media screen and (min-width: 1400px) {
  .teamAppContainer_lg {
    grid-template-columns: 16.5% 83.5%;
  }
}

@media screen and (min-width: 1530px) {
  .teamAppContainer_lg {
    grid-template-columns: 17% 83%;
  }
}

/* .teamAppContainer_lg {
  grid-template-columns: 260px 1fr;
} */
.teamAppContainer_sm {
  grid-template-columns: 4% 96%;
}

.hideSidebar {
  grid-template-columns: 0% 100% !important;
}

@media screen and (max-width: 900px) {
  .teamAppContainer_sm {
    grid-template-columns: 0% 100%;
  }

  .teamAppContainer_lg {
    grid-template-columns: 10% 90%;
  }

  .hideSidebar {
    grid-template-columns: 100% !important;
  }

  .userManagementDialogToaster,
  .showToaster {
    width: 100% !important;
  }

  .menuOverlay::after {
    width: 100vw;
    background-color: transparent;
    content: "";
    position: fixed;
    height: 100vh;
    top: 0;
    z-index: 10;
    cursor: text !important;
  }
}

.common_container_header1 {
  font-size: 20px;
  font-weight: 600 !important;
  /* border-bottom: 1px solid lightgrey; */
  /* background-color: #fff; */
  padding: 10px 20px;
  /* padding-top: 20px; */
  z-index: 10;
  height: auto;
  min-height: 8.7vh;
}

.showToaster {
  position: sticky;
  top: 6.7% !important;
  z-index: 13;
  width: 100%;
  left: 0 !important;
}

.backgroundFix {
  display: none;
}

.sideBody {
  background-color: #fff;
  height: 100%;
}

.fullBody {
  height: 100vh;
}

.showEllipses {
  overflow: hidden;
  text-overflow: ellipsis;
}

#webpack-dev-server-client-overlay {
  display: none;
}

[type="checkbox"],
[type="radio"] {
  accent-color: black !important;
}

.yelloBtn .ui-dialog__footer button {
  background-color: #ffe600 !important;
  color: #2e2e38 !important;
  font-weight: 700 !important;
  border: 1px solid #2e2e38 !important;
}

.addMarginTop {
  margin-top: 20px;
}

.sideBody:has(.addMarginTop) .showToaster {
  padding-top: 5px !important;
  top: 5px !important;
  min-height: 10px;
}

.BBICON {
  font-size: 0.82em !important;
}

.userManagementDialogToaster,
.showToaster {
  position: fixed !important;
  width: -webkit-fill-available;
  z-index: 17 !important;
  width: 100% !important;
}

.ui-dialog__overlay {
  z-index: 16 !important;
}

ul.ui-list:has(li:first-child > div.ui-dropdown__item__main > div:empty) {
  display: none !important;
}

/*stop zooming on focus */
@media only screen and (min-width: 320px) and (max-width: 500px) {
  input[type="text"] {
    font-size: 16px;
  }
}