.navBarTabs__right-section__tabs__tab {
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  /* background-color: #e6e6e9; */
  padding: 7px 10px;
  border-radius: 3px;
}

.navBarTabs__right-section__tabs__tab:hover {
  background-color: #e6e6e9;
}

.navBarTabs__right-section__tabs__tab__active {
  background-color: #e6e6e9;
}

@media only screen and (max-width: 470px) {
  .navBarTabs__right-section__tabs__tab {
    font-size: 15px;
  }
}
