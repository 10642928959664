.confirmationpage-container {
  /* background-image: url("../../assets//WelcomeUI.jpg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  width: 100%;
  /* height: 100%; */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  position: absolute;
}
.confirmationpage-wrapper {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3% 6%;
  background-color: #2c2f3bbf;
  color: white;
  text-align: center; */
  width: 430px;
  margin: 0 auto;
  border: 1px solid #ddd;
  padding: 2rem 3rem 2rem 3rem;
  background: #ffffff;
  text-align: center;
}
.confirmationpage-heading {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 3rem;
}
.image-content {
  height: 7.5rem;
}
.confirmationpage-image {
  margin-bottom: 3rem;
}
.confirmationpage-content,
.confirmationpage-content2 {
  font-size: 17px;
}
.confirmationpage-content2 {
  padding-top: 10px;
}