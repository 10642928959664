.chatContent {
  min-width: 34rem;
  max-width: 34rem;
}
/* @media screen and (max-width: 900px) {
  .chatContent {
    width: 80vw;
  }
}*/
.customDialog_class {
  font-weight: 600;
  font-size: 13px;
}

.customDialog_class .ui-dialog__content .ui-radiogroup__item {
  flex-direction: row-reverse;
  margin-left: -0.5rem !important;
}
.method_two_spn {
  margin-right: 4px;
}

.customDialog_class .ui-dialog__content .ui-radiogroup {
  padding-top: 1rem;
}

.customDialog_class {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.customDialog_class .ui-radiogroup__item__label {
  margin-left: 0rem;
  padding: 3px;
  width: 98%;
}

.customDialog_class .ui-dialog__content {
  height: 50vh;
  padding-top: 1.8rem;
}
.customDialog_class .ui-dialog__content .ui-radiogroup__item:first-child {
  border-bottom: 1px solid lightgray;
  padding-bottom: 0.5rem;
}

.customDialog_class .ui-radiogroup__item__indicator {
  color: black;
}

.customDialog_class .ui-flex button:nth-child(1),
.customDialog_class .ui-flex button:nth-child(2) {
  padding: 0px 16px;
  background-color: white;
  color: #2e2e38;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #2e2e38;
  transition: all 0.1s;
  height: 34px;
  font-family: inherit;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
}

.customDialog_class .ui-flex button:nth-child(2) {
  background-color: #ffe660;
}

@media screen and (max-width: 600px) {
  .chatContent {
    min-width: auto;
  }
}

.tocontainer {
  padding: 0.5rem;
}

.adaptiveCardcontainer {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}

.dropdown {
  margin-top: 8px;
}
.dropdown ul {
  max-height: 10rem;
  overflow-y: scroll;
}

.textarea,
textarea:focus,
textarea {
  border: none;
  padding: 10px;
  background-color: rgb(243 242 241);
  margin-top: 10px;
  resize: none;

  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.chat_component_loader {
  min-width: 38rem;
  max-width: 40rem;
  top: 0;
  left: 10px;
  transform: translateY(0%);
  height: max-content !important;
  min-height: 30rem;
  max-height: 85% !important;
}

.chatContent .adaptiveCardcontainer .textareaContainer {
  overflow-y: scroll;
  max-height: 12rem;
}

.ui-provider .ui-popup__content {
  z-index: 15;
}

.actionIcon-disable {
  color: gray;
  cursor: default;
}
.chatValidationError p,
.chatServerError p {
  color: #c4314b;
}

/* .root-162{ 
  padding: 0px 16px;
  background-color: white;
  color: #2e2e38;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #2e2e38;
  transition: all 0.1s;
  height: 34px;
  font-family: inherit;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
} */

.btn_enabled {
  padding: 0px 16px;
  background-color: #ffe600;
  color: #2e2e38;
  font-weight: 7009;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #2e2e38;
  transition: all 0.1s;
  height: 34px;
  font-family: inherit;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
}
.btn_enabled:hover {
  padding: 0px 16px;
  background-color: #ffe600;
  color: #2e2e38;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #2e2e38;
  transition: all 0.1s;
  height: 34px;
  font-family: inherit;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
}

.btn_disabled {
  border-radius: 4px;
  box-sizing: border-box;
  height: 35px;
}

.ms-Button--default {
  padding: 0px 16px;
  background-color: white;
  color: #2e2e38;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #2e2e38;
  transition: all 0.1s;
  height: 34px;
  font-family: inherit;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
}
.ms-Button--default:hover {
  padding: 0px 16px;
  background-color: white;
  color: #2e2e38;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #2e2e38;
  transition: all 0.1s;
  height: 34px;
  font-family: inherit;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
}
.chatPeoplePickerStyles .ui-dropdown__selected-items {
  background-color: rgb(243 242 241) !important;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}
.chatPeoplePickerStyles input {
  background-color: rgb(243 242 241) !important;
  box-shadow: none !important;
  border-color: none !important;
}
.chatPeoplePickerStyles .ui-dropdown__item:hover {
  background-color: rgb(243 242 241) !important;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .chat_component_loader {
    min-width: 100%;
  }
}
