.appDetailsCloseBtn {
    position: absolute !important;
    top: 10px !important;
    right: 20px !important;
    border-bottom: none !important;
    background-color: transparent !important;
}

@media (max-width: 600px) {
    .appDetailsCloseBtn {
        right: 2px !important;
    }
  }