.formPeoplePickerStyles .ui-dropdown__toggle-indicator {
  display: block;
}

.formPeoplePickerStyles .ui-dropdown__selected-items,
.formPeoplePickerStyles .ui-list,
.formPeoplePickerStyles .ui-dropdown__item {
  background-color: #f6f6fa;
}

.formPeoplePickerStyles input {
  background-color: #f6f6fa;
  border-radius: 0;
  box-shadow: none;
  border-color: none;
}

.peoplePickerdropdown .ui-dropdown__toggle-indicator {
  display: none;
}

.peoplePickerdropdown .ui-dropdown__container,
.peoplePickerdropdown .ui-dropdown__container:hover {
  border-bottom: 0.03vh solid black;
}

.peoplepicker_error .ui-dropdown__container {
  border-bottom-color: rgb(196, 49, 75) !important;
}

.peoplePickerdropdown .ui-dropdown__selecteditem__icon {
  margin-top: 3px !important;
}

.peoplePickerdropdown .ui-dropdown__selecteditem__icon .ui-icon svg {
  height: 0.8rem !important;
}

.peoplePickerdropdown .ui-dropdown__selecteditem {
  overflow: inherit !important;
  min-width: max-content !important;
  /* background-color: white !important;
  opacity: 1 !important; */
}

.peoplePickerdropdown .ui-dropdown__selecteditem__header {
  overflow: inherit !important;
}

.peoplePickerdropdown .ui-dropdown__items-list {
  transform: translate(0px, 36px) !important;
}

.hideDropdownList .ui-list {
  display: none;
}

.noResultMessage .ui-dropdown__items-list {
  display: none !important;
}
.disableSelectedVals .ui-dropdown__selected-items {
  cursor: text;
  pointer-events: none;
  background-color: #f6f6fa !important;
}
.disableSelectedVals .ui-dropdown__selecteditem {
  color: #939191 !important;
}
