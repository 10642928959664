.booleanExpressionEditor {
    padding: 6px 12px;
    background: #e7e7ea;
    border-radius: 6px;
    /* font-weight: bold; */
    border: none;
}

.booleanExpressionEditor:focus {

    border: none;
}