.modalPopupForPolls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pollPopupBody {
  flex: 2;
  min-height: 5rem;
  padding: 1rem 2rem 2rem 2rem;
  overflow-y: auto; 
  -webkit-overflow-scrolling: touch;
}

.popupLeftHeader {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.pollPopupHeader {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#popupLeftHeaderImg {
  object-fit: cover;
  width: 45px;
  height: 45px;
  border-radius: 4px;
}

#popupLeftHeaderImgVL {
  object-fit: cover;
  width: 15%;
  border-radius: 4px;
}

.popupHeaderTitle {
  font-weight: 700;
}

.modalPopup .ms-Dialog-main {
  width: 40%;
  height: auto;
  top: 5%;
}

.bullentinModalPopup .ms-Dialog-main {
  width: 70%;
  height: auto;
  top: 5%;
}

@media only screen and (min-width: 320px) and (max-width: 700px) {
  .modalPopup .ms-Dialog-main {
    width: 65%;
    height: auto;
    top: 5%;
  }

  .bullentinModalPopup .ms-Dialog-main {
    width: 80% !important;
    height: auto;
    top: 5%;
  }
}

.deepLinkPopupStyles {
  padding: 0rem !important;
  max-width: 65% !important;
  max-height: 90% !important;
  height: max-content !important;
  /* width: 75% !important; */
}

.BBDeepLinkPopupStyles {
  padding: 0rem !important;
  max-width: 75% !important;
  max-height: 90% !important;
  height: max-content !important;
  width: 75% !important;
}

@media only screen and (max-width: 2560px) {
  .BBDeepLinkPopupStyles #ui-flex-subheader > div > .ui-text {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .BBDeepLinkPopupStyles .summaryStyles {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.popupStyles {
  height: 85vh;
  padding: 5px;
  border-radius: 2px;
}
@media only screen and (min-width: 320px) and (max-width: 700px) {
  .deepLinkPopupStyles {
    max-width: 100% !important;
    /* width: 100% !important; */
  }
  .BBDeepLinkPopupStyles {
    max-width: 100% !important;
    width: 100% !important;
  }
}
