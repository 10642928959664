.mtextInputStyles input , .mtextInputStyles input:focus {
    background-color: #f6f6fa !important;
    
    /* border: 0.175vh solid gray;
    border-radius: 5px; */
    border: none;
    border-radius: 5px;
    border-color: transparent;
}

.filteredColumnResultsCheckBox {
    display:block;
    max-height: 200px; /* Set your desired height */
    overflow: auto !important;
}
.filteredColumnResultsCheckBox .checkBoxDiv .ui-text{
    text-transform: capitalize;
}
.minCharMessage, .recordsMessage{
padding-bottom: 5px;
font-size: 10px;
}
.recordsMessage{
padding-bottom: 5px;
font-size: 12px;
margin-left: 2px;
}

.filterFooterButtons, .filteredResultsCheckBox{

    margin-top: 15px;
}

/* .checkBoxDiv{
    font-size: 12px;
} */
.FilterIconClass:focus {
    outline: none;
    border: none;
    font-size:20px;
    margin-left:  5px;
}
.FilterIconClass{
    margin-left: 5px;
    font-size:20px;
}
/* .mtextInputStyles{

    z-index: 999;
} */

.filterSearchIconClass{

    font-size:23px; 
  
    background-color: #f6f6fa !important;
}
.mtextInputStyles{
    border: 0.175vh solid gray;
    border-radius: 5px;
    background-color: #f6f6fa !important;

}
.search-filter-input{
    width: 100%;
    background-color: #f6f6fa;
    border-bottom: 1px solid black;
    height: 2.5rem;
}
.search-filter-input input{
    background-color: #f6f6fa;
}
.search-filter-input input:focus {
    border:none
}
.search-filter-icons{
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.filteredColumnResultsCheckBoxLoader .ui-loader__indicator {
    display: flex;
    align-items: center;
    justify-content: center;
}
.filteredColumnResultsCheckBoxLoader svg {
    height: 15px;
    width: 15px;
}
.search-icon{
    cursor: pointer;
}
.clear-icon{
    cursor: pointer;
}
.initialLoaderSearchFilter .ui-popup__content__content{
    padding: 5px 5px 0px 5px !important;
    /* width: 15.8rem; */
    height: 2.5rem;
}
.popupSearchFilter .ui-popup__content__content{
    padding: 5px 5px 0px 5px !important;
}
.search-filter-input .ui-input__input::placeholder {
    font-size: 11x !important;
}
.checkBoxDiv .showOnlyOnChecked .ui-checkbox__indicator {
    background-image : url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' role='presentation' style='background-color: black; padding: 2px;' focusable='false' viewBox='8 8 22.5 22.5'><g><path fill='%23fff' d='M23.5 11.875a.968.968 0 0 1-.289.711l-8.25 8.25c-.192.193-.43.289-.711.289s-.519-.096-.711-.289l-4.75-4.75a.965.965 0 0 1-.289-.711c0-.125.027-.25.082-.375s.129-.234.223-.328a.953.953 0 0 1 .695-.297c.135 0 .266.025.391.074.125.05.231.121.32.215l4.039 4.047 7.539-7.547a.886.886 0 0 1 .32-.215c.125-.049.255-.074.391-.074a1.004 1.004 0 0 1 .922.625.97.97 0 0 1 .078.375z' /></g></svg>") !important;
    border-left-color: rgb(34, 15, 15) !important;
    border-right-color: black !important;
    border-top-color: black !important;
    border-bottom-color: black !important;
  }
  
  .checkBoxDiv .showOnlyOnChecked .ui-checkbox__indicator:hover {
    background-image : url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' role='presentation' style='background-color: black; padding: 2px;' focusable='false' viewBox='8 8 22.5 22.5'><g><path fill='%23fff' d='M23.5 11.875a.968.968 0 0 1-.289.711l-8.25 8.25c-.192.193-.43.289-.711.289s-.519-.096-.711-.289l-4.75-4.75a.965.965 0 0 1-.289-.711c0-.125.027-.25.082-.375s.129-.234.223-.328a.953.953 0 0 1 .695-.297c.135 0 .266.025.391.074.125.05.231.121.32.215l4.039 4.047 7.539-7.547a.886.886 0 0 1 .32-.215c.125-.049.255-.074.391-.074a1.004 1.004 0 0 1 .922.625.97.97 0 0 1 .078.375z' /></g></svg>") !important;
    border-left-color: black !important;
    border-right-color: black !important;
    border-top-color: black !important;
    border-bottom-color: black !important;
  }
 