.BulletinBoard {
  /* border-left: 1px solid #d8d4d4; */
}

.BulletinBoardContainer {
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f5f5f5 !important;
}

@media (max-width: 700px) {
  .BulletinBoardContainer {
    padding: 10px;
    width: 100%;
  }

  .bulletinCardContainer {
    width: 100% !important;
  }

  .BB-MyShortcutsHeader {
    top: -5px !important;
    padding-right: 3px;
    padding-left: 3px;
  }

  .shortcut-button {
    margin: 20px 20px 0 0 !important;
  }
}

.shortcut-button {
  margin: 40px 40px 0 0;
}

.bulletinBoardLoader {
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 20px;
  background: whitesmoke;
}

.sideBody:has(.BulletinBoard) {
  background-color: #f5f5f5;
}

.BB-MyShortcutsHeader {
  cursor: pointer;
  right: 0;
  top: -20px;
  padding-right: 5px;
  height: 20px;
}

.BB-MyShortcutsButton {
  border: 1px solid black !important;
  padding: 6px 10px 6px 10px !important;
  font-size: 14px !important;
  gap: 6px !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

.BB-MyShortcutsMenuList {
  padding: 10px 10px 0px 10px !important;
  margin-top: 5px !important;
  background-color: white !important;
  border-radius: 4px !important;
  border: 1px solid lightgray !important;
  font-size: 14px !important;
  width: 180px !important;
  cursor: pointer !important;
}

.BB-MyShortcutsDeleteIcon {
  opacity: 0.5 !important;
  float: right !important;
  font-size: 12px !important;
  margin-top: 6px;
  cursor: pointer !important;
}
