.popUpContent {
    width: 100%;
}

.tocontainer{
    margin-top: 0;
}

h3{
    margin-bottom: 15px;
    color: #242424;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 700;
    font-size: 16px;
}

.subtitle{
    font-weight: 400;
    font-size: 12px;
    color: #616161;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-bottom: 10px;
}

.mail_component_loader {
    min-width: 38rem;
    max-width: 40rem;
    top: 0;
    left: 10px;
    transform: translateY(0%);
    height: max-content !important;
    min-height: 30rem;
    max-height: 85% !important;
}

.popUpContent .adaptiveCardcontainer .textareaContainer{
    overflow-y: scroll;
    max-height: 12.5rem;
  }
  .sendLink_component_headerBold {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    word-break: break-word;
  }

  .mailServerError p {
    color: #C4314B;
  }

  .btn_enabled {  
    padding: 0px 16px;
    background-color: #ffe600;
    color: #2e2e38;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    border: 1px solid #2e2e38;
    transition: all 0.1s;
    height: 34px;
    font-family: inherit;
    text-align: center;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .btn_enabled:hover{  
    padding: 0px 16px;
    background-color: #ffe600;
    color: #2e2e38;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    border: 1px solid #2e2e38;
    transition: all 0.1s;
    height: 34px;
    font-family: inherit;
    text-align: center;
    box-sizing: border-box;
    border-radius: 4px;
  }
  
  .btn_disabled{
    border-radius: 4px;
    box-sizing: border-box;
    height: 35px;
  }
  
  .ms-Button--default{
    padding: 0px 16px;
    background-color: white;
    color: #2e2e38;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    border: 1px solid #2e2e38;
    transition: all 0.1s;
    height: 34px;
    font-family: inherit;
    text-align: center;
    box-sizing: border-box;
    border-radius: 4px; 
  }

  .ms-Button--default:hover{
    padding: 0px 16px;
    background-color: white;
    color: #2e2e38;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    border: 1px solid #2e2e38;
    transition: all 0.1s;
    height: 34px;
    font-family: inherit;
    text-align: center;
    box-sizing: border-box;
    border-radius: 4px; 
  }
  .sendLinkPeoplePickerStyles .ui-dropdown__selected-items{
    background-color: rgb(243 242 241) !important;
    border-top-right-radius: 4px !important;
    border-top-left-radius: 4px !important;
  }
  .sendLinkPeoplePickerStyles input{
    background-color: rgb(243 242 241) !important;
    box-shadow: none !important;
    border-color: none !important;
  }
  .sendLinkPeoplePickerStyles .ui-dropdown__item:hover{
    background-color: rgb(243 242 241) !important;
  }

  @media only screen and (min-width: 320px) and (max-width: 580px){

    .popUpContent{
      width: 100%;
    }
}
  
@media only screen and (min-width: 320px) and (max-width: 768px){
  .mail_component_loader{
    min-width: 100%;
  }
  
}
