.cscontainer {
    padding: 0px;
    margin: 0;
    width: 100%;
  }
  
  .header {
    font-size: 20px;
    font-weight: 600;
    border-bottom: 1px solid lightgrey;
  }
  
  .header p {
    margin: 20px 40px 0 40px;
    font-size: 24px;
  }
  
  .subtitle {
    border-bottom: 1px solid lightgrey;
    display: flex;
    justify-content: space-between;
    margin: 0px 40px;
  }
  
  .leftMenu {
    display: flex;
    align-items: center;
  }
  
  .leftMenu p {
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 600;
    padding-left: 5px;
    position: relative;
    top: 10px;
  }
  
  .leftMenu svg {
    position: relative;
    top: 10px;
  }
  
  .rightMenu {
    font-weight: 600;
    margin-bottom: 0px;
  }
  
  .rightMenu p {
    margin-top: 25px;
    margin-bottom: 0 !important;
  }
  
  .cscontent {
    text-align: center;
    padding-top: 15%;
  }
  
  .cscontent p {
    margin-bottom: 0px;
  }
  
  .cscontentHeader {
    font-weight: 700;
    font-size: 14px;
  }
  
  .cscontentSubtext {
    font-size: 12px;
  }
  
  .guidesCardsName {
    padding: 10;
    font-weight: 600;
    text-align: left;
    margin: 10px 17px !important;
  }
  