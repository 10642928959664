.datagrid_container {
  margin: 15px 40px;
}

.powerK_datagrid_container {
  margin: 15px 15px;
}

.datagrid_menucontainer {
  border-bottom: 1px solid lightgrey;
  padding: 0.5rem 1rem;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.powerK_datagrid_menucontainer {
  display: none;
  justify-content: space-between;
}

.datagrid_container input[type="checkbox"] {
  cursor: pointer;
  accent-color: black;
}

.detailslist_wrapper {
  max-height: 30rem;
  overflow-y: auto;
  min-height: 20rem;
}

.datagrid_menulabel {
  margin-left: 0.5rem;
  font-size: 14px;
}

.datagrid_container input[type="checkbox"]:hover {
  cursor: pointer;
  accent-color: black;
}

.datagrid_edit,
.datagrid_download,
.datagrid_ppodupload,
.datagrid_publish,
.datagrid_refresh,
.datagrid_delete,
.datagrid_ActionItems,
.clearSelection-clearBtn {
  cursor: pointer;
  font-weight: 600;
  align-items: center;
}

.datagrid_disabled {
  pointer-events: none;
  cursor: default !important;
  color: #a9a9a9;
}

.ms-ContextualMenu-container {
  overflow-y: hidden;
}

.ms-DetailsRow {
  min-width: fit-content;
}

.datagrid_icon_mb {
  margin-bottom: 3px;
}

.datagrid_icon_mb:hover {
  cursor: pointer;
}

/* div.ms-DetailsHeader-cellIsCheck {
  visibility: hidden !important;
}

*/
.datagrid_menucontainer .actionBtns,
.clearSelection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.datagrid_menucontainer .actionBtns {
  gap: 25px;
}

.clearSelection .clearSelection-clearBtn {
  font-size: 22px;
  padding: 2px;
}

.clearSelection-clearBtn:active {
  fill: #a9a9a9;
}

@media only screen and (min-width: 320px) and (max-width: 389px) {
  .datagrid_container {
    margin: 15px 10px;
  }
}

.mobileClass {
  gap: 15px !important;
}

@media only screen and (min-width: 389px) and (max-width: 768px) {
  .datagrid_container {
    margin: 15px 15px;
  }

  .mobileClass {
    gap: 15px !important;
  }
}

.refresh_hidden {
  display: none;
}

.my-custom-filter-icon {
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.searchAndFilterComponent-circleNumber {
  width: 55px !important;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.circleNumber {
  position: relative;
  margin-left: 5px;
  background: #5b5fc7;
  font-size: 10px;
  height: 22px !important;
  width: 22px !important;
  border: 2px solid transparent;
  font-size: 10px;
  text-align: center;
  border-radius: 50%;
  color: white;
  pointer-events: none;
}
.columnName-filter-icon {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noMatchRecords {
  background-color: #f6f6fa;
  height: 120px;
  text-align: center;
  display: flex;
  align-items: center;
  margin-top: -10px;
  width: 100%;
  position: sticky;
  inset: 0;
  justify-content: space-around;
  font-size: 16px;
  color: #a9a9a9;
}

.ms-DetailsList-headerWrapper {
  position: sticky;
  inset: 0;
  background-color: white;
  z-index: 1;
}
.searchAndFilterComponent-circleNumber {
  width: 55px !important;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.columnName-filter-icon {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.seperateBorder {
  border-left: 1px solid gray;
  height: 20px;
}
.row_wrapper {
  padding-bottom: 25px;
}
.noteMessageContainer {
  display: flex;
  align-items: center;
  color: black;
  padding-top: 4px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 1rem;
}
.noteMessageContainer .noteMessage {
  width: calc(100% - 38px);
}
