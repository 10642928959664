.mtextInputStyles_Dynamic input,
.mtextInputStyles_Dynamic input:focus {
    background-color: #f6f6fa !important;

    /* border: 0.175vh solid gray;
    border-radius: 5px; */
    border: none;
    border-radius: 5px;
    border-color: transparent;
}

.searchFilterPills_span_Dynamic {
    color: rgba(13, 110, 253, 1);
    margin-top: 10px;
    max-width: 98%;
    width: max-content;
    background-color: rgba(170, 210, 236, 0.2);
    border-radius: 10px;
    font-size: 12px;
    display: flex;
    gap: 2px;
    flex-direction: row;
    height: 25px;
    align-items: center;
}

.searchFilterPills_span_Dynamic p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    padding-left: 5px;

}

.searchFilterPillsParent_Dynamic {
    max-height: 180px;
    overflow: auto !important;

}

.checkBoxClass_Dynamic span {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.closeIcon-SearchFilterPills_Dynamic svg {
    padding: 3px;
    cursor: pointer;
}

.filteredColumnResultsCheckBox_Dynamic {
    display: block;
    max-height: 200px;
    /* Set your desired height */
    overflow: auto !important;
}

.filteredColumnResultsCheckBox_Dynamic .checkBoxDiv_Dynamic .ui-text {
    text-transform: capitalize;
}

.minCharMessage_Dynamic,
.recordsMessage_Dynamic {
    padding-bottom: 5px;
    font-size: 10px;
}

.recordsMessage_Dynamic {
    padding-bottom: 5px;
    font-size: 12px;
    margin-left: 2px;
}

.filterFooterButtons_Dynamic,
.filteredResultsCheckBox_Dynamic {

    margin-top: 15px;
}

/* .checkBoxDiv{
    font-size: 12px;
} */
.FilterIconClass_Dynamic:focus {
    outline: none;
    border: none;
    font-size: 20px;
    margin-left: 5px;
}

.FilterIconClass_Dynamic {
    margin-left: 5px;
    font-size: 20px;
}

/* .mtextInputStyles{

    z-index: 999;
} */

.filterSearchIconClass_Dynamic {
    font-size: 23px;
    background-color: #f6f6fa !important;
}

.mtextInputStyles_Dynamic {
    border: 0.175vh solid gray;
    border-radius: 5px;
    background-color: #f6f6fa !important;
}

.search-filter-input-div-dynamic .ui-input  .ui-input__input {
    width: 100%;
  }
  
.search-filter-input_Dynamic {
    width: 100%;
    background-color: #f6f6fa;
    border-bottom: 1px solid black;
    height: 2.5rem;
}

.search-filter-input_Dynamic input {
    background-color: #f6f6fa;
}

.search-filter-input_Dynamic input:focus {
    border: none
}

.search-filter-icons_Dynamic {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.filteredColumnResultsCheckBoxLoader_Dynamic .ui-loader__indicator {
    display: flex;
    align-items: center;
    justify-content: center;
}

.filteredColumnResultsCheckBoxLoader_Dynamic svg {
    height: 15px;
    width: 15px;
}

.search-icon_Dynamic {
    cursor: pointer;
}

.clear-icon_Dynamic {
    cursor: pointer;
}

.initialLoaderSearchFilter_Dynamic .ui-popup__content__content {
    padding: 5px 5px 0px 5px !important;
    /* width: 15.8rem; */
    height: 2.5rem;
}

.popupSearchFilter_Dynamic .ui-popup__content__content {
    padding: 5px 5px 0px 5px !important;
}

.search-filter-input_Dynamic .ui-input__input::placeholder {
    font-size: 11x !important;
}

.checkBoxDiv_Dynamic .showOnlyOnChecked_Dynamic .ui-checkbox__indicator {
    background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' role='presentation' style='background-color: black; padding: 2px;' focusable='false' viewBox='8 8 22.5 22.5'><g><path fill='%23fff' d='M23.5 11.875a.968.968 0 0 1-.289.711l-8.25 8.25c-.192.193-.43.289-.711.289s-.519-.096-.711-.289l-4.75-4.75a.965.965 0 0 1-.289-.711c0-.125.027-.25.082-.375s.129-.234.223-.328a.953.953 0 0 1 .695-.297c.135 0 .266.025.391.074.125.05.231.121.32.215l4.039 4.047 7.539-7.547a.886.886 0 0 1 .32-.215c.125-.049.255-.074.391-.074a1.004 1.004 0 0 1 .922.625.97.97 0 0 1 .078.375z' /></g></svg>") !important;
    border-left-color: rgb(34, 15, 15) !important;
    border-right-color: black !important;
    border-top-color: black !important;
    border-bottom-color: black !important;
}

.checkBoxDiv_Dynamic .showOnlyOnChecked_Dynamic .ui-checkbox__indicator:hover {
    background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' role='presentation' style='background-color: black; padding: 2px;' focusable='false' viewBox='8 8 22.5 22.5'><g><path fill='%23fff' d='M23.5 11.875a.968.968 0 0 1-.289.711l-8.25 8.25c-.192.193-.43.289-.711.289s-.519-.096-.711-.289l-4.75-4.75a.965.965 0 0 1-.289-.711c0-.125.027-.25.082-.375s.129-.234.223-.328a.953.953 0 0 1 .695-.297c.135 0 .266.025.391.074.125.05.231.121.32.215l4.039 4.047 7.539-7.547a.886.886 0 0 1 .32-.215c.125-.049.255-.074.391-.074a1.004 1.004 0 0 1 .922.625.97.97 0 0 1 .078.375z' /></g></svg>") !important;
    border-left-color: black !important;
    border-right-color: black !important;
    border-top-color: black !important;
    border-bottom-color: black !important;
}

.search-filter-input_Dynamic .ui-input__input::placeholder {
    width: 100%;
    background-image: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' role='presentation' style='font-color : grey;margin-top:0px; padding:0px; padding-top:3px; font-size:12px;' viewBox='0 0 24 24'><path d='M15.5 14h-.79l-.28-.27a6.471 6.471 0 0 0 1.57-5.09C15.98 4.81 13.17 2 9.74 2S3.5 4.81 3.5 8.64s2.81 6.63 6.24 6.63c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-5.76 0c-2.96 0-5.36-2.4-5.36-5.36S6.78 3.28 9.74 3.28s5.36 2.4 5.36 5.36-2.4 5.36-5.36 5.36z'/></svg>") !important;
    background-repeat: no-repeat;
    padding-top: 2px;
    padding-left: 20px;
    font-size: 13px;
}