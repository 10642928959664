.notification_count {
  background-color: #f95d54;
  color: white;
  display: inline-flex;
  position: relative;
  border-radius: 50%;
  height: 1.2rem;
  width: 1.2rem;
  right: 0.4rem;
  top: -0.3rem;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  font-size: 9px;
}
