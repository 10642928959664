.calender_component_container {
  min-width: 30rem;
  min-height: 22rem;
  max-width: 34rem;
  padding: 0px 13px;
}

.calender_component_headerBold {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  word-break: break-word;
}

.calender_component_loader {
  min-width: 37rem;
  max-width: 37rem;
  top: 60px;
  left: 0px !important;
  transform: translateY(0%);
  min-height: 32rem;
  height: max-content !important;
  max-height: 85% !important;
}

.calender_component_card .linkCard .textareaContainer {
  overflow-y: scroll;
  max-height: 12rem;
}

.calendarPeoplePickerStyles .ui-dropdown__selected-items {
  background-color: rgb(243 242 241) !important;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.calendarPeoplePickerStyles input {
  background-color: rgb(243 242 241) !important;
  box-shadow: none !important;
  border-color: none !important;
}

.calendarPeoplePickerStyles .ui-dropdown__item:hover {
  background-color: rgb(243 242 241) !important;
}

.calender_component_timepicker>* {
  margin-right: 1rem;
}

.calender_component_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
}

.calender_component_datepicker {
  display: flex;
  align-items: center;
}

.ui-provider .ui-popup__content {
  z-index: 16;
}

.calender_component_row .ui-button {
  display: none;
}

.calender_component_row .ui-dropdown {
  width: 7.2rem;
}

.calender_component_row .ui-dropdown__container {
  height: 2rem;
}

.calender_component_row .is-checked .ms-Toggle-innerContainer button {
  background: rgb(91, 95, 199);
}

.calender_component_toggleContainer {
  display: flex;
  flex: 1;
  align-items: center;
  align-self: end;
  justify-content: flex-end;
}

.calender_component_timepicker {
  display: flex;
  flex: 2;
  /* padding-right: 3rem; */
  align-items: center;
}

.calender_component_timepicker svg {
  height: 1rem;
  width: 1rem;
}

.calender_component_timepicker p {
  margin-bottom: 0;
}

.calender_component_toggleContainer p {
  margin-bottom: 0.6rem;
  margin-right: 0.5rem;
}

.calender_component_calenderIcon {
  margin-right: 1rem;
}

.calender_component_timepicker .ui-dropdown ul {
  max-height: 14rem;
}

.calender_component_peoplepicker {
  margin-bottom: 0.5rem;
}

.calender_component_card {
  display: flex;
}

.calender_component_card textarea {
  height: 4rem;
}

.menuIocn {
  margin-right: 1rem;
}

.linkCard {
  flex: auto;
  width: 95%;
}

.datePicker {
  position: relative;
}

.datePicker .ax {
  display: block;
}

.dropdownIcon::after {
  content: "\251B";
  position: absolute;
  font-size: 17px;
  top: 5px;
  transform: rotate(45deg);
  z-index: 0;
  right: 11px;
}

.calendarDatePicker input {
  width: 107px;
  z-index: 1;
  background: transparent;
  cursor: pointer;
  margin-top: 5px;
}

/* .datePicker svg {
  margin-top: 6px;
} */

.calender_component_datepicker .calendarDatePicker {
  background-color: #f3f2f1;
  /* border-radius: 2px; */
}

.actionIcon-disable {
  color: gray;
  cursor: default;
}

.calenderServerError p {
  color: #c4314b;
}

.btn_enabled {
  padding: 0px 16px;
  background-color: #ffe600;
  color: #2e2e38;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #2e2e38;
  transition: all 0.1s;
  height: 34px;
  font-family: inherit;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
}

.btn_enabled:hover {
  padding: 0px 16px;
  background-color: #ffe600;
  color: #2e2e38;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #2e2e38;
  transition: all 0.1s;
  height: 34px;
  font-family: inherit;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
}

.btn_disabled {
  border-radius: 4px;
  box-sizing: border-box;
  height: 35px;
}

.ms-Button--default {
  padding: 0px 16px;
  background-color: white;
  color: #2e2e38;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #2e2e38;
  transition: all 0.1s;
  height: 34px;
  font-family: inherit;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
}

.ms-Button--default:hover {
  padding: 0px 16px;
  background-color: white;
  color: #2e2e38;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #2e2e38;
  transition: all 0.1s;
  height: 34px;
  font-family: inherit;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
}

.spnDiff {
  width: 3rem;
}

.calendar_title {
  padding-left: 0rem !important;
}

/* @media only screen and (min-width: 390px) and (max-width: 580px) {
  .linkCard {
    width: 48%;
  }

  .calender_component_container {
    min-width: 17rem;
  }
  .calender_component_row {
    display: block;
  }
  .calender_component_timepicker {
    display: block;
    padding-right: 0px;
  }
  .calender_component_timepicker .ui-dropdown {
   overflow: hidden;
   margin-bottom: 3rem;
  }

  .calender_component_timepicker .ui-dropdown .ui-dropdown__container {
   position: absolute;
   width: 40%;
   }


  .divtill , .divClock {
    float: left;
  }
  .calender_component_timepicker .spnDiff {
    position: relative;
    left: 192px;
    top: -40px;
  }
  .calender_component_toggleContainer {
    justify-content: flex-start;
    padding-left: 0.5rem;
  }
} */

@media only screen and (min-width: 320px) and (max-width: 580px) {
  .linkCard {
    width: 48%;
  }

  .calender_component_container {
    min-width: 17rem;
  }

  .calender_component_row {
    display: block;
  }

  .calender_component_timepicker {
    display: block;
    padding-right: 0px;
  }

  .calender_component_timepicker .ui-dropdown {
    overflow: hidden;
    margin-bottom: 3rem;
  }

  .calender_component_timepicker .ui-dropdown .ui-dropdown__container {
    position: absolute;
    width: 40%;
  }

  .calender_component_timepicker .spnDiff {
    position: relative;
    left: 180px;
    top: -40px;
  }

  .calendarDatePicker input {
    width: 156px;
  }


  .divtill,
  .divClock {
    float: left;
  }

  .calender_component_toggleContainer {
    justify-content: flex-start;
    padding-left: 0.5rem;
  }
}

@media only screen and (min-width: 540px) and (max-width:580px) {
  .calender_component_timepicker .spnDiff {
    position: relative;
    left: 274px;
    top: -40px;
  }
}

@media only screen and (min-width: 451px) and (max-width:539px) {
  .calender_component_timepicker .spnDiff {
    position: relative;
    left: 240px;
    top: -40px;
  }
}

@media only screen and (min-width: 390px) and (max-width:450px) {
  .calender_component_timepicker .spnDiff {
    position: relative;
    left: 220px;
    top: -40px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .calender_component_loader {
    min-width: 100%;
  }

}


.calendarDatePicker .msDatePickerDisabled {
  visibility: hidden !important;
  position: relative;
  width: 0px;
}

.calendarDatePicker .msDatePickerDisabled::after {
  content: "";
  visibility: visible;
  top: 30%;
  height: 10px;
  width: 10px;
  /* border: 2px solid; */
  /* background-color: red; */
  font-size: 17px;
  /* border: 10px solid; */
  border-bottom: 2px solid;
  border-left: 2px solid;
  position: absolute;
  transform: rotate(-45deg);
  left: -6px;
  cursor: pointer;
}

.calendarDatePicker .ms-TextField-fieldGroup {
  border: none !important;
  background: #f3f2f1 !important;
}

.calendarDatePicker div div span div {
  position: absolute;
  width: 100vw
}