.headerStyle{
    max-width: "100%"
}
.pStyle{
    display: "flex"
}
.downloadReportClass{
    font-size: 14px;
    font-weight: 600;
    color: black;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
}
.missingDataDownloadFont{
    font-size: 15px !important;
}
.topbar_internalApp{
    gap: 10px !important;
}
.missingDataAlert{
    padding: 0px 5px !important;
}
.rightMenu_second_internalApp{
    display: flex;
    flex-direction: row;
    margin: 0px 20px;
    align-items: center;
}
.visualizationIcons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1% 2% 0% 2%;
    border-bottom: 1px solid rgb(211, 211, 211);
    padding-bottom: 5px;
    background-color: #fff;
}
.visualizationLeftIcons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}
.backToSearchIcon {
    color: black;
    text-decoration: none;
}
.uploadReportIconClass{
    font-size: 14px;
    font-weight: 600;
    color: black;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
}
 .missingDataAlertContent{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 5px;
    font-size: 14px;
 }
 .flex-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
 }
 .left-side {
    flex: 10;
    padding-right: 10px;
 }
.right-side {
    display: flex;
    flex-direction: row;
    gap: 7px;
    flex: 2 1;
    justify-content: end;
}
.missingLeftContainer{
    justify-content: flex-end;
}
.missingData{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 7%;
}
.missingDataP{
    font-size: 20px;
    font-weight: 700;
}
.missingDataP2{
    font-weight: 600;
    font-size: medium;
    text-align: center;
    margin: 0px 120px;
}
.missingData > img{
    width: 7%;
}
.rightSideUrls{
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: flex-end;
}
.custom_report_tablinks {
    width: 200px;
    min-width: 120px;
    text-align: center;
}
@media (max-width: 768px) {
    .custom_report_tablinks:not(:first-child) {
        border-left: 1px solid #bababa !important;
    }
    .custom-report-org-dropdown-btn{
        max-width: 120px;
    }
    .custom_report_dropdown_class{
        max-width: 200px;
    }
}
@media (min-width: 425px) and (max-width: 768px) {
    .etr-breakdown-title-and-dropdown .graph-title{
        align-items: end !important;
    }
}
@media (max-width: 425px) {
    .custom_report_dropdown_class{
        max-width: 120px;
    }
    .custom-report-org-multiselect-dropdown-btn-container{
        flex-direction: column;
        align-items: baseline !important;
        gap: 2px;
    }
    .etr-breakdown-title-and-dropdown .graph-title{
        align-items: end !important;
    }
}
@media (min-width: 425px) {
    .etr-breakdown-title-and-dropdown{
        align-items: end !important;
    }
}
.tip2div{
    width: max-content !important;
}
.graph-title{
    display: flex;
    flex-direction: row;
    align-items: end;
}
.etr-waterfall-title, .TaxExpenseBreakdown_container .graph-title{
    align-items: end !important;
} 