.submenu_name {
  width: 16.1em;
  padding: 13px 13px 13px 0;
  text-decoration: none !important;
  color: #000;
  font-size: 0.9em;
  display: flex;
  justify-content: space-between;
}

.submenu_name:hover {
  background-color: #fff;
  cursor: pointer;
  overflow-x: visible;
  width: 100%;
}

.add_padding {
  padding-left: 4.2em;
  width: 100%;
}

.active_submenu {
  background-color: #fff;
  font-weight: bold;
}
