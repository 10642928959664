.ErrorAttach{
    border: 1px solid rgb(196, 49, 75);
}
.ui-attachment__progress-container::after {
    content: '';
    width: 50%;
    height: 100%;
    background: black;
    position: absolute;
    top: 0;
    animation: barWidth 3s linear infinite;
}
.removeTranform .ui-attachment__progress-container::after{
    animation: none !important;
    width: 0% !important;
}
@keyframes barWidth {
    0% {
        left: 0;
        transform: translateX(-100%);
      }
    100% {
        left: 100%;
        transform: translateX(0%);
    }
}
.attachmentStyles{
    font-weight: 600;
    max-width: none;
    text-align: start;
    background-color: #fafafc;
    overflow: initial;
    border-radius: 0px;
}
.attachmentSizeStyles{
    flex-direction: column;
    display: flex;
    position: relative;
    margin-left: 24px;
    font-style: normal;
    font-weight: 100;
}
#attachmentStylesId:hover{
    background-color: #fafafc;
}
.ui-attachment__body{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ui-attachment__body .ui-attachment__description{
    display: flex;
    position: relative;
    margin-right: 10px;
    font-weight: 600;
    font-size: small;
}