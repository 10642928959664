.reaction {
  display: flex;
  align-items: center;
  gap: 6px;
}

.reaction-pill {
  background: transparent;
  border: 1px solid lightgrey;
  padding: 5px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
}

.reaction-pill:hover {
  background: #f0f0f0;
  border-color: #666;
}

.more-reactions-tooltip-item,
.primary-reaction-tooltip-item {
  display: flex;
  padding-top: 10px;
  font-size: 14px;
}

.more-reactions-header,
.primary-reaction-header {
  font-size: 12px;
  padding: 5px 0px 5px 0px;
  color: lightgrey;
}

.more-reactions-tooltip-item-image,
.primary-reaction-tooltip-item-image {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}
