.topbarDiv {
  width: 100%;
  position: sticky;
  display: flex;
  background: #f5f5f5;
  align-items: center;
  top: 0;
  height: 40px;
  border-bottom: 0.5px solid #74748055;
  z-index: 10;
  /* top most z-index order */
}

.topbarDiv-web {
  width: 100%;
  position: sticky;
  display: flex;
  background: #f5f5f5;
  align-items: center;
  top: 50px;
  height: 40px;
  border-bottom: 0.5px solid #74748055;
  z-index: 8;
  /* top most z-index order */
}

.hamburgerMenu {
  margin: 15px 15px 15px 20px;
  cursor: pointer;
  min-width: 18px;
  min-height: 18px;
}

.shareIcon-container {
  padding-right: 4px;
  padding-left: 4px;
  cursor: pointer;
  height: 18px;
}

.disable-web-version {
  color: darkgray;
}

.hamburgerdiv {
  padding-right: 2%;
}

hr {
  display: block;
  height: 1px;
  border: 1px;
  border-top: 1px solid #ccc;
  padding: 0;
}

.hamburgerdiv_dropdown {
  width: 10%;
}

/* .topbarDiv .drpContainer {
  border-right: 1.5px solid lightgrey;
} */

@media screen and (max-width: 750px) {
  .hamburgerdiv_dropdown {
    width: 10%;
  }
}

.teams_dropdown {
  width: 10% !important;
}

/* .root-97 {
  width: 10%;
} */

.topbar_notification_container {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  margin-right: 0.5rem;
  position: relative;
  align-items: center;
  /* gap: 7px; */
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
  .topbar_notification_container {
    margin-right: 0.2rem;
  }
}

@media only screen and (min-width: 390px) and (max-width: 680px) {
  .hamburgerMenu {
    margin: 15px 0 15px 5px;
  }

  .topbarDiv nav div,
  .topbarDiv nav span {
    display: none;
  }

  .topbarDiv nav div:last-child {
    display: block;
  }
}

@media only screen and (min-width: 320px) and (max-width: 389px) {
  .hamburgerMenu {
    margin: 15px 0 15px 15px;
  }

  .topbarDiv nav div,
  .topbarDiv nav span {
    display: none;
  }

  .topbarDiv nav div:last-child {
    display: block;
  }

  .drpContainer .ms-Dropdown-container .ms-Dropdown {
    min-width: 100px !important;
  }
}

.userManagementMenu,
.li-item-search,
.li-item-NotificationOption,
.li-item-whatsCatalyst {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 5px 0;
}

.userManagementMenu svg,
.li-item-search svg,
.li-item-NotificationOption svg,
.li-item-whatsCatalyst svg {
  font-size: 18px;
}

.dialogStyles .ui-dialog__footer button:last-child {
  background-color: #ffe600 !important;
}

/* .dialogStyles .ui-dialog__footer {
  padding-bottom: 20px;
} */

.dialogStyles .ui-dialog__footer button {
  padding: 0px 16px !important;
  background-color: rgb(255, 255, 255) !important;
  color: #2e2e38 !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  border: 1px solid #2e2e38 !important;
  transition: all 0.1s !important;
  height: 34px !important;
  font-family: inherit !important;
  text-align: center !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}

.topbar-usermanagement {
  height: 22px;
  padding-right: 2px;
}
