.ms-Panel-main {
  inset: 40px 0px 0px auto !important;
}

.ms-Panel-content {
  padding-left: 14px;
  padding-right: 14px;
}

.ms-Panel-navigation {
  border-bottom: 1px solid lightgrey;
}

.notifications-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* justify-content: space-between; */
  padding: 20px 40px;
  font-size: 14px;
  overflow-wrap: anywhere;
}

.notifications-close {
  float: right;
  width: 16px !important;
  padding-top: 10px;
  cursor: pointer;
}

.showAll_deleteAll_btn {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: #155cb4;
  padding: 0 10px;
}

.showAll_deleteAll_btn>div {
  border-bottom: 2px solid #155cb4;
  cursor: pointer;
  margin-bottom: 20px;
}

.showAllNotification {
  text-decoration: none;
  border-bottom: 2px solid #155cb4;
  cursor: pointer;
  margin-bottom: 20px;
  color: #155cb4 !important;
}
.modelvalidationdialog{
  z-index: 9999999 !important;
  position: absolute !important;
}

.notifications__container {
  display: flex;
  padding: 10px;
}

.unread_dot {
  content: "";
  width: 13px;
  height: 13px;
  background-color: #ed6500;
  border-radius: 13px;
  margin: 10px;
}

.notification {
  line-height: 22px;
  margin-top: 5px;
  width: 100%;
}

.notification_title {
  font-weight: bold !important;
}

.notification_body {}

.notification_footer {
  display: flex;
  justify-content: space-between;
}

.notification_time {
  font-weight: normal !important;
}

.notification_read_delete_btns {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-right: 20px;
  color: #155cb4;
  cursor: pointer;
  font-weight: bold !important;
}

.mark_read_btn {}

.mark_unread_btn {}

.mark_delete_btn {}

.notification_is_unread {
  font-weight: 600;
  background-color: #fafafc;
}

.no-notifications {
  text-align: center;
  margin-top: 100px;
  color: #212121;
}

.disable-notifications {
  color: #727171 !important;
  border: none !important;
  cursor: text !important;
}

/* .ui-dialog__overlay {
  z-index: 17 !important;
} */

.notificationHeader {
  background-color: #fff;
  border-bottom: 1px solid #d3d3d3;
}

.validationContent {
  font-size: 14px;
}

/* confirmButton  */
.deleteNotificationsStyles .ui-dialog__footer button {
  padding: 0px 16px !important;
  background-color: #ffe600 !important;
  color: #2e2e38 !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  border: 1px solid #2e2e38 !important;
  transition: all 0.1s !important;
  height: 34px !important;
  font-family: inherit !important;
  text-align: center !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}

/* confirmButton Shadow */
.deleteNotificationsStyles .ui-dialog__footer .li {
  box-shadow: none;
}

.deleteNotificationsStyles .ui-dialog__footer button:first-child {
  background-color: rgb(255, 255, 255) !important;
}

.deleteNotificationsStyles .ui-dialog__footer {
  border: none !important
}

@media only screen and (min-width: 320px) and (max-width: 389px) {
  .notifications-component {
    padding: 20px 4px;
  }
}

@media only screen and (min-width: 390px) and (max-width: 580px) {
  .notifications-component {
    padding: 20px 4px;
  }
}