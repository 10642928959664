.add-users__people-picker {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.add-users__people-picker .formPeoplePickerStyles {
  flex: 1 1;
}

/* .addMemberDialog {
    min-height: 80% !important;
    max-height: 80% !important;
} */

/* 
@media (max-width: 600px) {
    .addMemberDialog {
        min-height: 600px;
        max-height: 550px;
    }

} */
@media (min-width: 700px) {
  .add-users-container {
    width: 100%;
  }
  .manageTeamPopupStyle {
    width: 60% !important;
  }
}

@media (max-height: 650px) {
  /* .addMemberDialog {
        min-height: 450px !important;
        max-height: 450px !important;
    } */

  .users-results {
    max-height: 170px;
  }
}

.addMemberDialog .ui-dialog__footer {
  align-items: flex-end;
  display: flex;
}

.addMemberDialog .ui-dialog__footer .ui-button {
  margin-right: 2.5px;
}

/* .add-users-container {
    position: relative;
} */

/* .add-users-container::after {
    position: absolute;
    content: "";
    top: -50px;
    left: -6%;
    width: 120%;
} */

@media (max-width: 400px) {
  .add-users__people-picker {
    flex-direction: column;
    gap: 4px;
    width: 100%;
    align-items: normal;
  }

  .add-users__people-picker .teamsbtn button {
    width: 100% !important;
  }
}

.add-users__people-picker__dropdown .ui-dropdown__items-list {
  display: none;
  cursor: default;
}

.top-border-div {
  border-top: 0.2px solid #c4c4c4;
  width: 200vw;
  margin-left: -100px;
  margin-top: 10px;
}

.add-users__people-picker .formPeoplePickerStyles input {
  height: 35px;
}

.users-already-exist {
  background: #efefef;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
}

.users-already-exist svg {
  margin-right: 20px;
}

/* .addMemberDialog__overlay::after {
    background-color: rgb(237 237 237 / 22%) !important;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    z-index: 5;
} */

.addMemberDialog .ms-Overlay {
  background-color: rgb(0 0 0 / 75%) !important;
}

.add-user-overlayLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 19;
}

.addMemberDialog .popupHeader {
  align-items: center !important;
}

.addMemberDialog .popupHeader span {
  font-size: 18px !important;
  font-weight: 600;
}

.users-results {
  height: 270px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 10px;
}

ul.ui-list > li:first-child > div.ui-dropdown__item__main > div:empty {
  display: none !important;
}

/* 
  .row1 .ui-box{
    width: 90%;
  } 
  .row1 .ui-box .ui-box .ui-input__input{
    width: 96%;
  } */

.addExternalUSerDialog {
  width: 48rem !important;
  border-radius: 0px !important;
  display: flex !important;
  flex-direction: column;
  padding: 0rem !important;
  max-height: 85% !important;
  height: max-content !important;
}

.firstNameClass input,
.firstNameClass input:focus,
.lastNameClass input,
.lastNameClass input:focus,
.emailClass input,
.emailClass input:focus {
  background-color: #f6f6fa !important;
  border-bottom: 0.05vh solid black;
  border-radius: 0;
}

.firstNameClass,
.lastNameClass {
  width: 45%;
}

.firstNameClass .ui-input {
  width: 88%;
}

.firstNameClass .ui-input .ui-input__input {
  width: 100%;
}

.lastNameClass .ui-input {
  width: 88%;
}

.lastNameClass .ui-input .ui-input__input {
  width: 100%;
}

.emailClass {
  width: 45%;
}

.emailClass .formPeoplePickerStyles {
  width: 88%;
}

.locationClass {
  width: 40%;
}

.locationClass .ui-dropdown__container,
.locationClass .ui-dropdown__container:hover {
  background-color: #f6f6fa;
}

.emailClass .ui-input {
  width: 88%;
}
.locationClass .ui-input {
  width: 88%;
}
.emailClass .ui-input .ui-input__input {
  width: 100%;
}

.locationClass .ui-input .ui-input__input {
  width: 100%;
}

.firstRow {
  margin-bottom: 10px;
}

.addExternalUSerDialog .ui-dialog__content .popupContainer .popupBody {
  min-height: 19rem;
}
.inviteButtonClass {
  display: flex;
  flex-direction: row-reverse;
  /* padding: 0.5rem; */
  padding-top: 1rem;
  position: relative;
  right: 8px;
}

.addExternalUSerDialog .closeIconPopup {
  margin-right: 4px;
}
@media only screen and (min-width: 320px) and (max-width: 680px) {
  .addExternalUSerDialog {
    width: 100% !important;
  }

  .firstRow .ui-flex,
  .secondRow .ui-flex {
    flex-wrap: wrap;
  }
  .firstNameClass,
  .lastNameClass {
    width: 100%;
    margin-right: 0 !important;
    padding-bottom: 1rem !important;
  }
  .emailClass {
    width: 100%;
    margin-right: 0 !important;
    padding-bottom: 1rem;
  }
  .locationClass {
    width: 98%;
  }

  .inviteButtonClass .primaryBtn {
    position: relative;
    left: 2px;
  }

  .firstNameClass .ui-input,
  .lastNameClass .ui-input,
  .emailClass .ui-input,
  .emailClass .formPeoplePickerStyles {
    width: 98%;
  }
}
.SubNote {
  margin-top: 10px;
  font-size: 14px;
}
/* .userManagementDialog,
.userManagementDialog .ui-dialog__content,
.userManagementDialog .popupContainer,
.userManagementDialog .topContent,
.userManagementDialog .popupBody,
.userManagementDialog .popupFooter {
  opacity: 1 !important;
  background-color: white !important;
}
.userManagementDialog .popupBody {
  background-color: transparent !important;
} */
