.formLabelStyles {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    padding-bottom: 5px;
    text-transform: inherit;
    text-align: left;
  }
  .mandatoryField{
    color: rgb(196, 49, 75);
    padding-left: 2px;
  }
.dragdropItems .ui-box{
  width: max-content;
}
.reqOptionsStyles{
  color:rgb(196, 49, 75);
}
.additionalInfoIcon{
  margin-bottom: 3px;
  margin-left: 5px;
  margin-right: 5px;
}
