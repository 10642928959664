.sidebar {
  /* border-right: 1px solid lightgrey; */
  background-color: #f5f5f5;
  box-sizing: content-box;
  height: calc(100vh - 40px);
  position: sticky !important;
  top: 40px;
  transition: width 0.5s;
  transition: flex-basis 0.5s;
  z-index: 11;
}

.sidebar-web {
  /* border-right: 1px solid lightgrey; */
  background-color: #f5f5f5;
  box-sizing: content-box;
  height: calc(100vh - 90px);
  position: sticky !important;
  top: 90px;
  transition: width 0.5s;
  transition: flex-basis 0.5s;
  z-index: 11;
}

.scrollbar {
  overflow-y: auto;
  cursor: pointer;
}

.scrollbar::-webkit-scrollbar {
  width: 13px !important;
}

.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.scrollbar::-webkit-scrollbar-thumb {
  /* background-color: darkgrey;
  outline: 1px solid slategrey; */
  background-color: rgba(0, 0, 0, 0.25) !important;

}

/* .wd_sm{
  height: 100%;
} */

@media screen and (max-width: 900px) {
  .wd_sm {
    width: 100%;
  }

  .wd_lg {
    width: max-content;

  }

  .sidebar {
    transition: none;
  }
}

.sidebar a {
  text-decoration: none;
}

.sidebar a:hover {
  color: #000;
}

.rotate_180 {
  transform: rotate(90deg);
  transition: 0.5s transform;
}

.menu {
  background-color: #f5f5f5;
}

.menu_section {
  padding: 10px 0;
  background-color: #f5f5f5;
  text-decoration: none;
  cursor: default;
}

.menu_section__name {
  padding: 5px 22px;
  font-weight: 600;
  color: #434349;
  font-size: 0.8em;
  line-height: 1.1em;
}

.menu_section .hr {
  margin: 0;
  margin-top: 0.5rem !important;
}

.menu_component {
  background-color: #f5f5f5;
  width: 100%;
}