.teamsbtn button {
  /* width: 0.5rem; */
  margin: 0.2rem !important;
}

.btn_enabled,
.btn_enabled:hover {
  background-color: rgb(91, 95, 199);
}

.btn_disabled {
  background-color: #ebebeb;
}
.addoption_button ::first-letter {
  font-size: 18px;
}

@media only screen and (min-width: 390px) and (max-width: 450px) {
  .btn_enabled,
  .btn_enabled:hover {
    transition: background-color 0.1s, color 0.1s !important;
    pointer-events: all !important;
  }
  .btn_enabled:focus::after {
    outline: none !important;
  }
}
