.manageAccesscard {
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  width: 100%;
  display: flex;
  gap: 20px;
  width: 100%;
  padding: 14px 16px;
}
.popUpCard .manageAccesscard {
  padding: 0;
  border: none;
  display: block;
}
.popUpCard{
  min-height: 150px;
}
.manageAccessLoader {
  position: absolute !important;
  left: 45% !important;
  top: 45% !important;
}

.popUpCard .manageAccessCardLeft,
.popUpCard .manageAccessCardRight {
  width: 100%;
}
.manageAccessCardLeft {
  width: 60%;
}
.manageAccessCardRight {
  width: 40%;
  overflow: auto;
}
.manageAccesscardHeader {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 8px;
}
.manageAccessAttention {
  display: flex;
  align-items: center;
}
.attentionMessageHeader {
  width: 100%;
  margin: 0 auto;
  text-align: start;
}
.manageAccesscard .attentionMessageHeader {
  margin: 0px !important;
}
.manageAccesscard .attentionMessageHeader .contentList p {
  margin: 0px !important;
  width: 100%;
}
.attentionMessageHeader .manageAccessCardLeft .common_container_subtitle {
  margin: 10px 0px 0px !important;
}
.manageAccessCardLeft .commonTitleEllipses {
  font-size: 14px;
  font-weight: 500;
  margin-top: -3px;
  margin-left: 5px;
  font-weight: 600;
}
.shareFileAccessMsg {
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 10px;
}
.manageAccesscard .contentList p {
  font-size: 13px;
}
.manageAccesscard .ui-radiogroup__item {
  justify-content: space-between;
  flex-direction: row-reverse;
  padding-bottom: 4px;
  border-bottom: 1px solid #e6e6e9;
}
.manageAccesscard .ui-radiogroup__item__label {
  margin: 0px;
  width: calc(100% - 16px);
}
.manageAccesscard .ui-icon svg {
  color: #000;
}
.manageAccesscard .manageAccessCheckboxs {
  margin-top: 14px;
  width: 100%;
}
.manageAccesscard .accessFileContainer {
  display: flex;
  align-items: center;
}
.manageAccesscard .accessFileName {
  width: calc(100% - 52px);
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.manageAccesscard .accessFileIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}
.manageAccesscard .accessFileIcon.green {
  background-color: #229b9a;
}
.manageAccesscard .accessFileIcon.blue {
  background-color: #188ce5;
}
.manageAccesscard .accessFileIcon.violet {
  background-color: #9d7cd4;
}
.manageAccesscard .accessFileIcon.grey {
  background-color: #afaeba;
}
.manageAccesscard .sendLinkPeoplePickerStyles {
  display: flex;
  align-items: center;
  padding-bottom: 14px;
}
.manageAccesscard .formPeoplePickerStyles {
  width: calc(100% - 84px);
}
.manageAccesscard .AddedUserList {
  padding-left: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
  margin-bottom: 4px;
  border-bottom: 1px solid #eee;
}
.manageAccesscard .AddedUserList .userAvatar {
  display: flex;
  align-items: center;
  margin-right: 6px;
  font-size: 14px;
}
.manageAccesscard .AddedUserList .userAvatar .users-avatar {
  width: 32px;
  height: 32px;
}

.manageAccesscard .usersList {
  display: flex;
  align-items: center;
  width: calc(100% - 20px);
}
.manageAccesscard .userDetails .userName {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 0px;
}
.manageAccesscard .userDetails .userMailId {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 4px;
}
.manageAccesscard .removeUser {
  cursor: pointer;
  width: 20px;
}
.manageAccesscard.internalUserCardHeight .manageAccessCardRight {
  height: 274px !important;
}
.manageAccesscard .userDetails {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.manageAccesscard.clientUserCardHeight .manageAccessCardRight {
  height: 100% !important;
  max-height: 360px !important;
}
.popUpCard .manageAccesscard.internalUserCardHeight .manageAccessCardRight {
  height: 100% !important;
  max-height: 180px !important;
}
.popUpCard .manageAccesscard.clientUserCardHeight .manageAccessCardRight {
  height: 100% !important;
  max-height: 180px !important;
}
.duplicateUserError {
  font-size: 12px;
  color: #dc3545;
}
.popUpCard .manageAccessCardLeft {
  margin-top: 14px;
}
.popUpCard .manageAccessCardRight {
  margin-top: 14px;
}
.manageAccesscard
  .manageAccessCardLeft
  .peoplePickerdropdown
  .ui-dropdown__selecteditem__header {
  overflow: hidden !important;
  min-width: auto;
  max-width: 200px !important;
}
.manageAccessCheckboxs div[aria-disabled="true"] .accessFileIcon,
.manageAccessCheckboxs
  div[aria-disabled="true"]
  .ui-radiogroup__item__indicator {
  opacity: 0.5;
}
.manageAccessCheckboxs div[aria-disabled="true"] .ui-radiogroup__item__indicator {
  visibility: hidden;
}
@media only screen and (max-width: 728px) {
  .manageAccesscard {
    display: block !important;
    margin-top: 24px;
    height: 100% !important;
    margin-bottom: 60px;
    padding: 14px;
    padding: 14px;
  }
  .manageAccessCardLeft,
  .manageAccessCardRight {
    width: 100%;
  }
  .manageAccessCardRight {
    margin-top: 14px;
  }
  .manageAccessCheckboxs {
    width: 100%;
  }
  .manageAccesscard.internalUserCardHeight .manageAccessCardRight {
    height: auto !important;
    max-height: 230px !important;
  }
  .manageAccesscard.clientUserCardHeight .manageAccessCardRight {
    height: auto !important;
    max-height: 230px !important;
  }
  .manageAccessCardLeft .primaryBtn .ms-Button--primary {
    min-width: 50px !important;
    width: 50px !important;
  }
  .manageAccesscard .formPeoplePickerStyles {
    width: calc(100% - 54px);
  }
  .manageAccesscard .peoplePickerdropdown .ui-dropdown__selecteditem__header {
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 117px;
  }
  .manageAccesscard .userDetails .userName {
    font-size: 14px;
    padding-bottom: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 4px;
  }
  .manageAccesscard .userDetails .userMailId {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 4px;
  }
  .popUpCard .manageAccesscard .userDetails .userName {
    font-size: 14px;
    padding-bottom: 0px;
    width: auto !important;
  }
  .popUpCard .manageAccesscard {
    margin-bottom: auto;
  }
  .popUpCard .manageAccesscard .common_container_subtitle {
    padding-bottom: 4px;
  }
  .popUpCard .manageAccesscard .userDetails .userMailId {
    font-size: 12px;
    width: auto !important;
  }
  .dialogSubmit .ui-dialog__footer button {
    margin: 4px !important;
  }
  .dialogSubmit {
    height: 55% !important;
  }
}

.manageAccesscard .manageAccessCardLeft .ui-radiogroup__item {
  /* background-color: transparent !important; */
  /* opacity: 1 !important; */
  width: auto !important;
}
.manageAccesscard.hideManageAccessCard{
  display: none !important;
}
.manageAccesscard.showManageAccessCard{
  display: block !important;
}