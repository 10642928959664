.dropdownStyles .ui-dropdown__toggle-indicator {
  display: block;
}
.dropdownStyles .ui-dropdown__selected-items {
  background-color: #f6f6fa;
  width: 100%;
}
.dropdownStyles .ui-dropdown__item {
  width: 100%;
  color: rgb(37, 36, 35) !important;
}
.dropdownStyles input {
  background-color: #f6f6fa;
  border-radius: 0;
  box-shadow: none;
  border-color: none;
}
.dropdownStyles {
  border-bottom: 1px solid #2e2e38;
}

.dropdown_error {
  border-bottom-color: rgb(196, 49, 75) !important ;
}

.formDropdownStyles .ui-dropdown__container {
  width: 100%;
}
.formDropdownStyles .ui-dropdown__container .ui-dropdown__items-list {
  width: 100%;
}
