.membersList {
    display: flex !important;
    flex-direction: row !important;
    /* padding-top: 5px; */
    padding-left: 8px;
}
.users-avatar {
    position: relative;
    width: 25px; 
    height: 25px; 
  }
  .imageoverlapstyle {
    margin-left: -7px;
    border: 2px solid  white;
    
  }
  .users-avatar-additional {
    position: relative;  
     width: 25px !important;
    height: 25px !important;
  } 
  .add-image-overlap-style span {
    margin-left: -7px;
    border: 2px solid  white;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    cursor: pointer;
  }
 


