.textAreaStyles{
    border-bottom: 1px solid black ;
    border-radius: 0;
    background-color: #f6f6fa !important;
}
.formTextAreaStyles textarea, .formTextAreaStyles textarea:focus{
    margin-top: 0px !important;
    padding:10px !important;
    resize: none;
    overflow: auto;
    outline: none;
    border-bottom: 1px solid black ;
}

.formTextAreaStyles .textbox_error {
  
    border-bottom-color:rgb(196, 49, 75) !important ;
}
