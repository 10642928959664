.dragdropContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}

.dragdropContainer-marginLeft {
  margin-left: 2rem !important;
}
.dragdropItems {
  font-weight: normal;
  font-size: 10px;
  margin-right: 7px;
  padding: 11px 5px 3px;
  cursor: pointer !important;
}
.closeIconClass {
  background-color: black;
}

.selected-option-pill {
  display: flex;
  align-items: center;
  background-color: #000;
  border-radius: 20px;
  padding: 3px 7px;
  cursor: grab;
  color: #fff;
}

.selected-option-pill span {
  margin-right: 5px;
}
.pillsClass {
  cursor: default !important;
}
.remove-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 20px;
}
.isPillDisabled {
  pointer-events: none;
  background: white;
  opacity: 0.3;
}
.selected-option-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
