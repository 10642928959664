.unauthorized_container {
  text-align: center;
  padding-top: 15%;
  /* background: #f6f6f6; */
}

.unauthorized_container p {
  margin-bottom: 0.5rem;
}

#root {
  background: rgb(255, 255, 255);
}

.unauthorized_containerHeader {
  font-weight: 700;
  font-size: 14px;
  margin: 0.3rem;
}

.unauthorized_containerSubtext {
  font-size: 12px;
}

.unauthorized-img {
  mix-blend-mode: multiply;
  filter: contrast(1.1);
}