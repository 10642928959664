/* Implemented use of id's only to handle fluent ui component styling due to class overlapping. */

#ui-card {
  /* min-width: 276.67px; */
  background-color: #ffffff;
  /* border: 1px solid #e1e1e6; */
  border: 2px solid #d3d3da;
  padding: 0;
  margin-bottom: 5px;
  /*  */
  display: flex;
  cursor: default !important;
  /* justify-content: space-between; */
}

.card_height {
  min-height: 380px !important;
}

.promotional-tile {
  cursor: default !important;
}

#ui-card:hover {
  box-shadow: 0px 3px 11px -6px black;
}

#ui-card-header,
#ui-card-header-poll {
  padding: 8px 12px 0px 12px;
  margin-bottom: 0;
  cursor: default;
}

#ui-flex-header-row,
#ui-flex-header-row-poll {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
}
#ui-flex-header-row-usergroup {
  display: flex;
  align-items: center;
  padding-bottom: 1.5rem;
}

.ui-fy-label {
  font-size: 0.8rem;
  color: #000;
  font-weight: 550;
  border: 1px solid #2e2e38;
  border-radius: 2em;
  padding: 2px 12px;
}

.ui-bookmark-icon {
  font-size: 1.1rem;
  color: black;
}

.ui-bookmark-disable-icon {
  font-size: 1.1rem;
  color: grey;
  cursor: default;
}

.ui-bookmark-fill {
  color: #2e2e38;
}

.ui-bookmark-disable-fill {
  color: lightcoral;
  cursor: default;
}

.ui-flex-header-banner {
  position: relative;
}

#ui-card-banner {
  object-fit: cover;
  width: 100%;
  height: 136px;
}

#ui-card-banner-label {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 16px;
  margin: 1px 2px;
  max-width: 70%;
  line-height: 25px;
}

/* #ui-flex-subheader {
  padding-top: 16px;
} */

#ui-card-body {
  padding: 16px 16px 10px 16px;
  height: 202px;
  position: relative;
}
#ui-card-body-usergroup {
  padding: 16px 16px 10px 16px;
  height: 85px;
  position: relative;
  cursor: default !important;
}

.mdSettings {
  font-size: 21px;
  margin-bottom: 1px;
  cursor: pointer;
}
.selfgroup {
  font-size: 21px;
  margin-bottom: -1px;
  cursor: default;
}
.sharedgroup {
  font-size: 21px;
  margin-bottom: 0.5px;
  cursor: default;
}

/* .div_category{
  position: absolute;
  bottom: 5px;
  padding: 2px;
} */
.cardCategory {
  font-size: 12px;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  position: absolute;
  bottom: 5px;
  padding: 2px;
  word-break: break-all;
  /* 
  width: 100%; */
  margin-right: 14px;
}

/* #ui-flex-body .ui-text:not(:first-child) {
  margin-top: 10px;
} */

.search-promotional-tile {
  padding-top: 20px !important;
}

#ui-card-footer {
  margin: 0;
}

#ui-flex-body .ellipsisTitle {
  display: table-cell;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CardList {
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: space-around; */
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  padding: 0 15px;
  gap: 0 20px;
}

@media only screen and (max-width: 950px) {
  .CardList {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 480px) {
  .CardList {
    grid-template-columns: 1fr;
  }
}

.CardList #ui-card {
  margin-bottom: 1.5rem;
}

.CardListContainer #ui-card-banner,
.allCardsContainer #ui-card-banner {
  object-fit: cover;
  /* max-width: 204px; */
  height: 136px;
}

.cardTitle {
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.titleStyles {
  /* font-size: 15px; */
  word-break: break-word;
  /* width: 93%; */
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 0;
  font-size: 15px;
  padding-bottom: 3px;
}

.summaryStyles {
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 1em;
}

.descriptionStyles {
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 1em;
}

.CardComponent {
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 200px;
}

.deleteOption,
.editOption {
  display: flex;
  align-items: center;
  gap: 8px;
}

.menuList ul {
  border-radius: 0;
}
.menuList ul li a span {
  color: black !important;
  /* background: transparent !important;
  opacity: 1 !important; */
}

.loaderLayover > .ui-flex {
  display: contents;
}

.dialogLayover {
  pointer-events: none;
  background-color: rgba(255, 255, 255, 1) !important;
  z-index: 2;
}

.dialogDeleteStyles {
  height: 230px;
}

.pinnedContent > h2 {
  font-size: 17px;
  font-weight: 700 !important;
}

.dialogDeleteStyles .ui-loader {
  justify-content: flex-end;
}

.dialogDeleteStyles .ui-text {
  margin-left: 0;
}

.dialogLayover > * {
  opacity: 0.6;
}

.loaderLayover .ui-loader__indicator {
  position: absolute !important;
  left: 45% !important;
  margin: 0 !important;
}

/* .dialogLayover .ui-dialog__content {
  opacity: 1 !important;
} */

.dialogLayover .ui-loader__label {
  color: rgba(0, 0, 0, 0.6) !important;
}

/* .showTwoLineText{
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
} */
.uniqueIdStyles {
  font-size: 0.75rem;
}

.dialogDeleteStyles .ui-dialog__footer button {
  padding: 0px 16px !important;
  background-color: #ffe600 !important;
  color: #2e2e38 !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  border: 1px solid #2e2e38 !important;
  transition: all 0.1s !important;
  height: 34px !important;
  font-family: inherit !important;
  text-align: center !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}

/* confirmButton Shadow */
.dialogDeleteStyles .ui-dialog__footer .li {
  box-shadow: none;
}

.dialogDeleteStyles .ui-dialog__footer button:first-child {
  background-color: rgb(255, 255, 255) !important;
}

.dialogDeleteStyles .ui-dialog__footer {
  border: none !important;
  /* opacity: 1 !important; */
}

.dialogDeleteStyles .ui-dialog__footer > .ui-buttons {
  background-color: white;
}

.imageTitle {
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 15px;
}

.poll-status-active,
.poll-status-closing {
  color: #616161;
  border: 1px solid #616161;
}

.poll-status-closed {
  color: #616161;
  border: 1px solid #616161;
}

.poll-status-Draft {
  color: #0f6cbd;
  border: 1px solid #0f6cbd;
}

.poll-card-header {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
  margin-top: -2px !important;
}

.poll-card-header-active,
.poll-card-header-closing {
  border-top: 4px solid #616161 !important;
}

.poll-card-header-closed {
  border-top: 4px solid #616161 !important;
}

.poll-card-header-Draft {
  border-top: 4px solid #0f6cbd !important;
}

.poll-status-text {
  padding: 0px 6px;
  border-radius: 8px;
  font-size: 0.62rem !important;
  font-weight: 550;
}

.polls-titleStyles {
  font-size: 15px;
  word-break: break-word;
  font-weight: 700;
  margin-bottom: 8px !important;
}

#ui-polls-card-body {
  padding: 8px 12px 10px 16px;
  height: 275px;
  position: relative;
  cursor: default;
}

#ui-flex-subheader-polls {
  padding-bottom: 2px;
}

#ui-flex-subheader-dates-polls {
  padding-bottom: 8px;
}

#ui-flex-subheader-polls .ui-text,
#ui-flex-subheader-dates-polls .ui-text {
  font-size: 0.6rem;
}

.poll-question-option-outer {
  height: 205px;
  overflow: auto !important;
}

.grpClass {
  width: 100% !important;
}

.poll-button-footer {
  display: flex;
  align-items: center;
}

.poll-button-footer > *:first-child {
  margin-right: 1rem;
}

.poll-btn-submit {
  font-weight: 700;
  border: 1px solid #2e2e38 !important;
  padding: 0px !important;
  height: 27px !important;
  min-width: 60px;
}

.poll-btn-submit,
.poll-btn-submit:hover {
  background-color: #ffe600 !important;
  padding: 0px !important;
  height: 27px !important;
}

.poll-btn-view-result {
  background-color: white;
  border: 1px solid #2e2e38;
  font-weight: 700;
  min-width: 55px;
  padding: 0px !important;
  height: 27px !important;
}

.poll-btn-view-result:hover {
  background-color: white;
  border: 1px solid #2e2e38;
  padding: 0px !important;
  height: 27px !important;
}

#ui-card-header-poll .ui-bookmark-icon,
#ui-card-header-poll .ui-bookmark-fill,
#ui-card-header-poll .ui-bookmark-disable-fill,
#ui-card-header-poll .ui-bookmark-disable-icon {
  margin-top: 4px;
  margin-right: 5px;
}

.ui-bookmark-icon,
.ui-bookmark-fill {
  cursor: pointer;
}

.ui-bookmark-disable-fill,
.ui-bookmark-disable-icon {
  cursor: default;
}

.ui-card-poll-right-header > * {
  margin-right: 0.2em;
}

.unanswered-poll-image-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.unanswered-poll-outer {
  height: 240px !important;
}

.unanswered-poll-image {
  margin-bottom: 10px;
}

.unanswered-poll-txt {
  font-size: 14px;
  font-weight: 600;
}

.promotionalTileTitle {
  /* height: 20px !important;
  line-height: 20px !important;
  width: 90% !important;
  max-width: 160px !important;
  padding: 0px 10px 0px 5px !important;
  margin: 0px 0px 0px 5px !important;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 10px;
  transform: translateY(-550%);
  background-color: rgb(255, 230, 0) !important;
  color: #000000 !important;
  border-radius: 3px; */

  font-size: 10px !important;
  top: 0 !important;
  line-height: 20px !important;
  padding: 0px 10px 0px 5px !important;
  margin: 5px 0px 0px 5px !important;
  max-width: 90% !important;
  height: 20px !important;
  border-radius: 3px !important;
  color: #000000 !important;
  background-color: rgb(255, 230, 0) !important;
}

.poll-question-option-outer::-webkit-scrollbar-thumb:hover {
  border-left-width: 0.3rem !important;
  border-right-width: 0.3rem !important;
  border-bottom-width: 0.3rem !important;
  border-top-width: 0.3rem !important;
}

.poll-question-option-outer::-webkit-scrollbar-thumb {
  border-left-width: 0.4rem !important;
  border-right-width: 0.4rem !important;
  border-bottom-width: 0.4rem !important;
  border-top-width: 0.4rem !important;
}

@media only screen and (min-width: 320px) and (max-width: 580px) {
  .CardComponent {
    width: 95%;
  }
}

.ui-pin-icon {
  font-size: 1.3rem;
  color: black;
}

.ui-pin-disable-icon {
  font-size: 1.3rem;
  color: grey;
}

.ui-pin-disable-fill {
  font-size: 1.3rem;
  color: grey;
  cursor: default;
  cursor: pointer !important;
}

.bulletinBoardCardBody {
  display: flex !important;
  flex-direction: row !important;
  gap: 20px !important;
  padding: 15px 16px 15px 16px !important;
  height: 142px !important;
  border: 1px solid #d3d3da;
}

.bulletinBoardCardBody .cardCategory {
  bottom: 11px;
}

.bulletinBoardCardBody #ui-flex-body .ui-text:not(:first-child) {
  margin-top: 0;
}

.has-voted-for {
  font-weight: 700 !important;
}

@media only screen and (max-width: 820px) {
  .bulletinBoardCardBody {
    height: 300px !important;
    flex-direction: column !important;
  }

  .bulletinBoardCardBody #ui-card-banner {
    height: 130px !important;
  }
}

.bulletinBoardCardBody #ui-card-banner {
  object-fit: cover;
  width: 100%;
  /* height: 136px; */
  height: 100%;
  min-width: 200px;
}

.bulletinBoardCardBody #ui-flex-body {
  flex: 1;
  gap: 4px;
}

.bulletinBoardCardBody .titleStyles {
  font-size: 14px !important;
}

.bulletinBoardCardBody .summaryStyles {
  font-size: 1em !important;
}

.bulletinBoardCardBody #ui-flex-body > :not(:last-child) {
  margin-bottom: 0px !important;
}

.bulletinBoardCardHeader {
  padding-bottom: 0 !important;
}

.pinned-status {
  font-size: 13px;
}

.pinned-status span {
  font-size: 11px;
}

.layoverToPollCard {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 11;
}

.allPrivilegesBtns .disabled,
.allPrivilegesBtns .disabled:hover,
.contentAuthorPrivilegesBtns .disabled,
.contentAuthorPrivilegesBtns .disabled:hover {
  /* background-color: #ebebeb !important;
  opacity: 1 !important; */
  border: 0 !important;
  color: rgb(210, 208, 206) !important;
}

.contentAuthorPrivilegesBtns {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.allPrivilegesBtns {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.bulletinBoardPolls {
  height: auto !important;
}

.popupHeaderTitle {
  font-weight: 700;
  font-size: 17px;
}

.modalPopupDelete {
  margin: 20px;
}

.deletePopupHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.popupModal .ms-Dialog-main {
  width: 50%;
  height: auto;
  top: 5%;
  border-radius: 4px;
}

.loaderOnDelete {
  z-index: 2;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.5);
  min-width: 48%;
  min-height: 14em;
}

.titleContainer {
  display: flex;
}

.ui-pin-icon-cursor {
  cursor: pointer !important;
}

.hideMenu {
  display: none;
}

.hideMenu .ui-popup__content {
  display: none;
}
.manageAccessCardAvatars {
  position: relative !important;
  margin-right: 2px !important;
}
.manageAccessCardAvatars .member-dropdown {
  position: absolute !important;
  right: 80px !important;
  left: auto !important;
  width: -webkit-fill-available !important;
  min-width: 110px !important;
  z-index: 5 !important;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .poll-btn-view-result {
    min-width: 55px;
  }
}

/* .CardList .ui-card .ui-menubutton__menu, .CardList .ui-card .ui-menubutton__menu .ui-menu__itemwrapper, .CardList .ui-card .ui-menubutton__menu .ui-menu__itemwrapper .ui-menu__item{
  opacity: 1 !important;
} */
