
.textInputStyles input , .textInputStyles input:focus {
    background-color: #f6f6fa !important;
    border-bottom: 0.05vh solid black;
    border-radius: 0;
    color: var(--prompt-input-color);
}

.errMessage {
    font-size: 12px;
    color: rgb(196, 49, 75);
  }
.textbox_error .ui-box{

    border-bottom-color:rgb(196, 49, 75) !important ;
}

.additionalMessage{
    font-size: 12px;
}
  
